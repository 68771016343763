import { CREATION_CONSTANTS } from "../common/constant/FactoryConstants";
import { getCookieCustom } from "../common/utils/StorageUtils";
import DefectFactoryMapper from "./mapper/DefectFactoryMapper";

class DefectFactory {

    constructor() {
        this.factoryMapper = new DefectFactoryMapper();
    }

    getFlowType() {
        return getCookieCustom(CREATION_CONSTANTS.FLOW_TYPE);
    }

    getDefectType = (data, type) => {

        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getDefectType(data);
    }
    
    getDefectResolutionTypes = (data, type) => {
    
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getDefectResolutionTypes(data);    
    }
    
    createDefect = (data, type) => {
    
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.createDefect(data);
    }
    
    resolveDefect = (data, type) => {
        
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.resolveDefect(data);
    }
    
    getAllDefects = (data, type) => {
    
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getAllDefects(data);
    }
    
    getDefectById = (data, type) => {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getDefectById(data);
    }
}

export default DefectFactory;