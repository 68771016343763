import SideBar from "../../component/SideBar/SideBar";
import WebHeader from "../../component/WebHeader/WebHeader";
import "./MachineDetailsPage.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PAGE_CONST } from "../../common/constant/CommonConstants";
import moment from "moment";
import { redirect } from "../../common/utils/RedirectionUtils";
import { TIMELINE_CONST } from "../../common/constant/CommonConstants";
import part_details_delete_button from "../../common/icons/part_details_delete_button.svg";
import part_details_download_button from "../../common/icons/part_details_download_button.svg";
import { getMachinePartDetail } from "../../helper/ProductionOrderHelper";
import green_check_timeline from "../../common/icons/green_check_timeline.svg";
import green_substage_check_timeline from "../../common/icons/green_substage_check_timeline.svg";
import yellow_substage_timeline from "../../common/icons/yellow_substage_timeline.svg";
import yellow_check_timeline from "../../common/icons/yellow_check_timeline.svg";
import timeline_line from "../../common/icons/timeline_line.svg";

function MachineDetailsPage(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [dataContainer, setDataContainer] = useState({});
    const [activeTab, setActiveTab] = useState(PAGE_CONST.PART_DETAILS);

    useEffect(() => {
        handleMachineDetailGet(params.orderId, params.machineId);
    }, [])

    const handleMachineDetailGet = (orderId, machineId) => {
        getMachinePartDetail(orderId, machineId).
            then((res) => {
                console.log(res);
                setDataContainer(res);
            }).catch((err) => {
                console.log(err);
            })
    };


    const handleTypeChange = (page_type) => {
        // console.log(page_type);
        if (page_type === PAGE_CONST.PART_DETAILS) {
            document.querySelector(".part-listing-body").style.height = "100vh";
        } else {
            document.querySelector(".part-listing-body").style.height = "300%";
        }
        setActiveTab(page_type);
    };
    const editButtonHandler = () => {
        console.log("edit button was clicked");
    }


    const handleRedirect = (page_type, orderId) => {
        if (page_type === PAGE_CONST.PRODUCTION_ORDER_LISTING) {
            console.log("redirecting to polisting");
            redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_LISTING);
        } else if (page_type === PAGE_CONST.PRODUCTION_ORDER_DETAIL) {
            console.log("redirecting to this order id", orderId);
            redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_DETAIL, { orderId: orderId });
        }
    }
    const getPartDetailContainers = () => {
        if (dataContainer && dataContainer.components) {
            const divData = dataContainer.components.map(function (ele) {
                return (
                    <div className="common-part-container" key={ele.id}>
                        <div className="common-heading">{ele.component_type_label}</div>
                        {/* <div className="common-edit-button" onClick={editButtonHandler}>Edit</div> */}
                        <div className="common-part-id-class">{ele.id}</div>
                    </div>
                );
            })
            return divData;
        }
    }

    const getSubtageTimeline = (ele) => {
        if (dataContainer && dataContainer.timeline &&
            dataContainer.timeline.stages) {
            const divDataSubstage = ele.substages.map(function (subStage) {
                return (
                    <div className="parent-substage" key={subStage.id}>
                        <div className="substage-date-completion">
                            {subStage.completed_at && moment(subStage.completed_at).format(TIMELINE_CONST.DATE_FORMAT)}
                        </div>
                        <div className="substage-completion-icon">
                            <img className="timeline-line-class" src={timeline_line} />
                            <img src={subStage.status === "COMPLETED" ?
                                green_substage_check_timeline :
                                yellow_substage_timeline} />
                        </div>
                        <div className={subStage.completed_at === null ? "substage-info-nulltime" : "substage-info-class"}>
                            <div className="substage-completion-time">
                                {subStage.completed_at && moment(subStage.completed_at).format(TIMELINE_CONST.TIME_FORMAT)}
                            </div>
                            <div className="substage-name">
                                {subStage.name}
                            </div>
                        </div>
                    </div >
                )
            })
            return divDataSubstage;
        }
    }

    const getTimeLine = () => {
        if (dataContainer && dataContainer.timeline) {
            const divData = dataContainer.timeline.stages.map(function (ele) {
                return (
                    <div className={ele.status === "NOT_STARTED" ? "timeline-class blur" : "timeline-class"} key={ele.id}>
                        <div className="parent-stage" key={ele.id}>
                            <div className="stage-date-completion">
                                {ele.completed_at && moment(ele.completed_at).format(TIMELINE_CONST.DATE_FORMAT)}
                            </div>
                            <div className="stage-completion-icon">
                                <img src={ele.status === "COMPLETED" ?
                                    green_check_timeline :
                                    yellow_check_timeline} />
                            </div>
                            <div className={ele.completed_at ? "stage-info-class-active" : "stage-info-class"}>
                                <div className="stage-ele-time">
                                    {ele.completed_at && moment(ele.completed_at).format(TIMELINE_CONST.TIME_FORMAT)}
                                </div>
                                <div className="stage-ele-name">
                                    {ele.name}
                                </div>
                            </div>
                            {getSubtageTimeline(ele)}
                        </div>
                    </div>
                );
            })

            return divData;
        }
    }


    return (
        <div className="machine-details">
            <WebHeader />
            <SideBar />
            <div className="part-listing-body">
                <div className="machine-high-detail-container">
                    <div className="page-route-header">
                        <span className="blue-span" onClick={() => { handleRedirect(PAGE_CONST.PRODUCTION_ORDER_LISTING) }}>Production Orders</span>
                        <span> {`>`} </span>
                        <span className="blue-span" onClick={() => { handleRedirect(PAGE_CONST.PRODUCTION_ORDER_DETAIL, dataContainer.order_id) }}>{dataContainer.order_id}</span>
                        <span> {`>`} </span>
                        <span>Machine Details</span>
                    </div>
                    <div className="machine-high-detail">
                        <div className="machine-id-heading">
                            <span>{dataContainer.serial_name}</span>
                        </div>
                        <div className={`machine-status ${dataContainer.status}`}>
                            {dataContainer.status ? dataContainer.status.replace('-', " ") : dataContainer.status}
                        </div>
                        <div className="hidden-div"></div>
                        <div className="download-icon-class"><img src={part_details_download_button} /></div>
                        <div className="delete-icon-class"><img src={part_details_delete_button} /></div>
                    </div>
                </div>
                <div className="machine-details-listing-body ">
                    <div className="machine-details-header">
                        <span>Machine Details</span>
                    </div>
                    <div className="header-details-container">
                        <div className="common-machine-container">
                            <div className="common-heading"> Machine Type</div>
                            <div className="show-machine">{dataContainer.product_name}</div>
                        </div>
                        <div className="mid-container common-machine-container">
                            <div className="common-heading">
                                Production Order
                            </div>
                            <div className="machine-orderId">{dataContainer.order_id}</div>
                        </div>
                        <div className="common-machine-container">
                            <div className="common-heading"> Software Version</div>
                            <div className="">{dataContainer.software_version}</div>
                        </div>
                    </div>
                    <div className="page-toggle-container">
                        <div
                            className={activeTab === PAGE_CONST.PART_DETAILS ?
                                "common-toggle-class toggle-active" :
                                "common-toggle-class"}
                            onClick={() => {
                                handleTypeChange(PAGE_CONST.PART_DETAILS);
                            }}
                        >
                            Part Details
                        </div>
                        <div
                            className={activeTab === PAGE_CONST.TIMELINE ?
                                "common-toggle-class toggle-active" :
                                "common-toggle-class"}
                            onClick={() => {
                                handleTypeChange(PAGE_CONST.TIMELINE);
                            }}
                        >
                            Timeline
                        </div>
                    </div>
                    {activeTab === PAGE_CONST.PART_DETAILS && (
                        <div className="parts-container">
                            {getPartDetailContainers()}
                        </div>
                    )}
                    {activeTab === PAGE_CONST.TIMELINE && (
                        <div className="timeline-container">
                            {getTimeLine()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MachineDetailsPage;
