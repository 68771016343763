
const initialState = {
    machineDataReducer: {
        machineData: {}
    },
    userDetailsReducer: {
        userDetail: {}
    }
}

export default initialState;