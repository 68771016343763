import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import neev_logo from '../../common/icons/neev_logo.svg';
import { setCookieCustom } from '../../common/utils/StorageUtils';
import { sendOtp, verifyOtp } from '../../helper/LoginHelper';
import { LOGIN_CONST, PAGE_CONST } from '../../common/constant/CommonConstants';

import './LoginPage.css';
import { redirect } from '../../common/utils/RedirectionUtils';
import { handleOnFocusById } from '../../common/utils/CommonUtils';
import { setUserDetailsAction } from '../../modules/actions/action';
import { connect } from 'react-redux';
import { isMobile } from '../../common/utils/DeviceUtils';

function LoginPage(props) {

    const navigate = useNavigate();
    const [currentState, setCurrentState] = useState(LOGIN_CONST.SEND_OTP);
    const [submitButtonText, setSubmitButtonText] = useState(LOGIN_CONST.SEND_OTP);
    const [loginKey, setLoginKey] = useState("");
    const [otp, setOtp] = useState(null);
    const [dataFlag, setDataFlag] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loginType, setLoginType] = useState(LOGIN_CONST.EMAIL);

    useEffect(() => {
        handleDataCompletion();
    }, [loginKey, otp]);

    const handleDataCompletion = () => {
        let dataFlag = false;

        setDataFlag(dataFlag);
        if (loginKey) {
            dataFlag = true;
            if (currentState === LOGIN_CONST.VERIFY_OTP && !otp) {
                dataFlag = false;
            }
        }

        setDataFlag(dataFlag);
    }

    const updateCurrentState = (updatedState) => {
        if (updatedState === LOGIN_CONST.SEND_OTP) {
            setOtp(null);
            setCurrentState(LOGIN_CONST.SEND_OTP);
            setSubmitButtonText(LOGIN_CONST.SEND_OTP);
        } else if (updatedState === LOGIN_CONST.VERIFY_OTP) {
            setCurrentState(LOGIN_CONST.VERIFY_OTP);
            setSubmitButtonText(LOGIN_CONST.VERIFY_OTP);
        }
    }

    const handleChange = (type, event) => {
        let value = (event && event.target && event.target.value) || "";

        setErrorMsg(null);

        switch (type) {
            case LOGIN_CONST.LOGIN_KEY:
                // TODO: add validation

                if (loginType === LOGIN_CONST.MOBILE_NUMBER && value && value.length !== 10) {
                    setErrorMsg(LOGIN_CONST.MOBILE_NUMBER_ERROR);
                }
                setLoginKey(value);
                updateCurrentState(LOGIN_CONST.SEND_OTP);
                break;
            case LOGIN_CONST.OTP:
                setOtp(value);
                break;
            default:
                break;
        }
    }

    const handleCheckBoxClick = (loginType) => {

        setLoginType(loginType);
        setLoginKey("");
        setErrorMsg(null);
    }

    const handleSubmit = () => {

        if (dataFlag) {
            let reqData = {
                value: loginKey,
                loginType: loginType
            }
            if (currentState === LOGIN_CONST.SEND_OTP) {
                sendOtp(reqData)
                    .then((data) => {
                        updateCurrentState(LOGIN_CONST.VERIFY_OTP);
                    }).catch((err) => {
                        console.log(err);
                        setErrorMsg(err);

                    })
            } else if (currentState === LOGIN_CONST.VERIFY_OTP) {
                reqData.otp = otp;

                verifyOtp(reqData)
                    .then((data) => {
                        // console.log(data);
                        const access_token = data[LOGIN_CONST.ACCESS_TOKEN];
                        const refresh_token = data[LOGIN_CONST.REFRESH_TOKEN];
                        if (access_token && refresh_token) {
                            setCookieCustom(LOGIN_CONST.ACCESS_TOKEN, access_token);
                            setCookieCustom(LOGIN_CONST.REFRESH_TOKEN, refresh_token);
                        }

                        if (data.user) props.setUserDetailsAction(data.user);

                        if (isMobile()) {
                            redirect(navigate, PAGE_CONST.HOME_PAGE, null);
                        } else {
                            redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_LISTING, null);
                        }
                    }).catch((err) => {
                        setErrorMsg(err);
                        console.log(err);
                    })
            }
        }
    }

    return (
        <div className="login-page">

            <div className="logo-container">
                <img className="logo-img" src={neev_logo} alt="Back Button" />
            </div>
            <div className="login-form">
                <div className="login-form-heading">Sign In</div>
                <div className="login-checkbox-container">
                    <div className="mobile-container">
                        <input type="checkbox" name={LOGIN_CONST.MOBILE_NUMBER} className="checkbox-round"
                            checked={loginType === LOGIN_CONST.MOBILE_NUMBER} onChange={() => handleCheckBoxClick(LOGIN_CONST.MOBILE_NUMBER)} />
                        <span className="login-checkbox-text">Mobile Number</span>
                    </div>
                    <div className="mobile-container">
                        <input type="checkbox" name={LOGIN_CONST.EMAIL} className="checkbox-round"
                            checked={loginType === LOGIN_CONST.EMAIL} onChange={() => handleCheckBoxClick(LOGIN_CONST.EMAIL)} />
                        <span className="login-checkbox-text">Email</span>
                    </div>
                </div>
                <div className="input-container">
                    <input className="input-box" id="login-key" type={loginType === LOGIN_CONST.EMAIL ? "email" : "tel"}
                        placeholder={loginType === LOGIN_CONST.MOBILE_NUMBER ? "Enter Mobile Number" : "Enter Email"}
                        value={loginKey} onChange={(event) => handleChange(LOGIN_CONST.LOGIN_KEY, event)}
                        onFocus={() => handleOnFocusById("login-key")} required />
                </div>
                {currentState === LOGIN_CONST.VERIFY_OTP && <div className="input-container">
                    <input className="input-box" id="login-otp" type="text" autoComplete="off" placeholder="Enter OTP"
                        onChange={(event) => handleChange(LOGIN_CONST.OTP, event)} onFocus={() => handleOnFocusById("login-otp")} />
                </div>}
                {errorMsg && <div className="error">{errorMsg}</div>}
                <div className="submit-button-container">
                    <button className={dataFlag ? "save-button" : "save-button button-disabled"} onClick={handleSubmit}>{submitButtonText}</button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {

    return {
        userDetail: state.userDetailsReducer.userDetail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserDetailsAction: (payload) => dispatch(setUserDetailsAction(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);