import { 
    createMachineAPI,
    getAllMachineTypeAPI,
    getMachineComponentsAPI,
    getMachineProgressDetailsAPI,
    getMachineStageDetailsAPI,
    getMachineStagesAPI,
    getMachineSubStageDetailsAPI,
    getMachineTimeLineAPI,
    postMachineSubStageDetailsAPI,
    updateMachineStageStatusAPI,
    updateMachineStatusAPI 
} from "../../adapter/machineCreationAdapter";

export class MachineCreationFactory {

    constructor() {
        // add something
    }

    getAllType = () => {
 
        let promise = new Promise(function(resolve, reject) {

            getAllMachineTypeAPI().then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    reject("error occured");
                }
            });
        })
    
        return promise;
    }

    create = (data) => {
    
        let requestData = {
            product_id: data.selectedMachineType,
            machine_name: data.machineName
        }
    
        var promise = new Promise(function(resolve, reject) {

            createMachineAPI(requestData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    if (res.data.error && res.data.error.message) {
                        res.data.error.code = "MACHINE_ALREADY_EXIST";
                        reject(res.data.error);
                    } else {
                        reject("error occured");
                    }
                }
            });
        })
    
        return promise;
    }

    getProgressDetails = (machineId) => {

        let requestData = {
            machine_name: machineId
        }
    
        var promise = new Promise(function(resolve, reject) {
            getMachineProgressDetailsAPI(requestData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    getSubStageDetails = (data) => {
    
        let requestData = {
            machine_id: data.machineId,
            stage_id: data.stageId,
            sub_stage_id: data.subStageId
        }
    
        var promise = new Promise(function(resolve, reject) {
            getMachineSubStageDetailsAPI(requestData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
    
                    let response = res.data.data;
                    // response.machine_name = "Test Machine";
                    response.sub_stage_name = res.data.data.machine_sub_stage_name;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            }).catch((err) => {
                console.log("error occured in stage details Api :: " + err);
            });
        })
    
        return promise;
    }
    
    postSubStageDetails = (data) => {
    
        var promise = new Promise(function(resolve, reject) {
            postMachineSubStageDetailsAPI(data).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    response.machine_name = "Test Machine";
                    response.sub_stage_name = "Sub Station - 1";
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                    else reject("error occured");
                }
            }).catch((err) => {
                console.log("error occured in stage details Api :: " + err);
            });
        })
    
        return promise;
    }
    
    getStageDetails = (data) => {
    
        let reqData = {
            machine_id: data.machineId,
            stage_id: data.stageId
        }
        var promise = new Promise(function(resolve, reject) {
            getMachineStageDetailsAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
    
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            }).catch((err) => {
                console.log("error occured in stage details Api :: " + err);
            });
            
        })
    
        return promise;
    }
    
    updateStageStatus = (data) => {
    
        let reqData = {
            status: data.status,
            stage_id: data.stageId
        }
    
        var promise = new Promise(function(resolve, reject) {
            updateMachineStageStatusAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    updateStatus = (data) => {
    
        let reqData = {
            machine_id: data.machineId,
            status: data.status
        }
    
        var promise = new Promise(function(resolve, reject) {
            updateMachineStatusAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    getComponents = (data) => {
    
        let reqData = {
            machine_id: data.machineId,
        }
    
        var promise = new Promise(function(resolve, reject) {
            getMachineComponentsAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    getStages = (data) => {
    
        let reqData = {
            machine_id: data.machineId,
        }
    
        var promise = new Promise(function(resolve, reject) {
            getMachineStagesAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("error occured")
                }
            });
        })
    
        return promise;
    }
    
    getTimeLine = (data) => {
    
        let reqData = {
            machine_id: data.machineId,
        }
    
        var promise = new Promise(function(resolve, reject) {
            getMachineTimeLineAPI(reqData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("error occured")
                }
            });
        })
    
        return promise;
    }
}