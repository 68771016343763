import {Types} from "./actionTypes";

export const setMachineDetailsAction = task => {
    return {
        type: Types.SAVE_MACHINE_DATA,
        payload: task
    }
}

export const setUserDetailsAction = task => {
    return {
        type: Types.USER_DETAILS,
        payload: task
    }
}

export const setActiveSubStageAction = (task) => {
    return {
        type: Types.ACTIVE_SUBSTAGE,
        payload: task
    }
}
