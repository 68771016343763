import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import back_svg from '../../common/icons/ep_back.svg';
import defect_svg  from '../../common/icons/defect.svg';
import defect_red_svg from '../../common/icons/defect_red.svg';
import machine_progress  from '../../common/icons/machine_progress.svg';

import "./SubHeader.css";
import { redirect } from '../../common/utils/RedirectionUtils';
import { PAGE_CONST } from '../../common/constant/CommonConstants';

export default function SubHeader (props) {
    const navigate = useNavigate();

    const handleDefectClick = () => {
        redirect(navigate, PAGE_CONST.DEFECT_PAGE)
    }
    return (
        <div className={props.isBlur ? "stage-header blur-8" : "stage-header"}>
            <div className="stage-heading">
                {props.showBackButton && <img src={back_svg} alt="Back Button" onClick={props.handleBackClick}/>}
                <span className="stage-header-machine-name">{props.machineName}</span>
            </div>
            <div className="stage-header-icons">
                {props.showDefects && <div className="danger">
                    {<img src={!props.pendingDefect ? defect_svg : defect_red_svg} alt="danger svg" onClick={() => handleDefectClick()}/>}
                </div>}
                {props.showTimeline && <div className="in-progress">
                    <img src={machine_progress} alt="machine progress svg" onClick={props.handleMachineTimeLineClick}/>
                </div>}
            </div>
        </div>
    );
}
