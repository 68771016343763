import { CREATION_CONSTANTS } from "../common/constant/FactoryConstants";
import { getCookieCustom } from "../common/utils/StorageUtils";
import CreationFactoryMapper from "./mapper/CreationFactoryMapper";

class CreationFactory {

    constructor() {
        this.factoryMapper = new CreationFactoryMapper();
    }

    getFlowType() {
        return getCookieCustom(CREATION_CONSTANTS.FLOW_TYPE);
    }

    getAllType(data, type) {

        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getAllType(data);
    }

    create(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.create(data);
    }

    getProgressDetails(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getProgressDetails(data);
    }

    getSubStageDetails(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getSubStageDetails(data);
    }

    postSubStageDetails(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.postSubStageDetails(data);
    }

    getStageDetails(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getStageDetails(data);
    }

    updateStageStatus(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.updateStageStatus(data);
    }

    updateStatus(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.updateStatus(data);
    }

    getComponents(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getComponents(data);
    }

    getStages(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getStages(data);
    }

    getTimeLine(data, type) {
        if (!type) type = this.getFlowType();
        const factory = this.factoryMapper.factory(type);
        return factory.getTimeLine(data);
    }
}

export default CreationFactory;
