import { PAGE_CONST } from "../constant/CommonConstants";

export const redirect = (navigate, page, data) => {
    switch (page) {
        case PAGE_CONST.LOGIN_PAGE:
            navigate('/login');
            break;
        case PAGE_CONST.HOME_PAGE:
            navigate('/home-page');
            break;
        case PAGE_CONST.ASSEMBLY_TIMELINE_PAGE:
            navigate('/machine-progress/assembly/timeline')
            break;
        case PAGE_CONST.MACHINE_REGISTRATION_PAGE:
            navigate('/machine-creation')
            break;
        case PAGE_CONST.MACHINE_REGISTRATION_PAGE_SCAN:
            navigate('/machine-creation/?type=scan')
            break;
        case PAGE_CONST.MACHINE_PROGRESS_PAGE:
            navigate('/machine-progress')
            break;
        case PAGE_CONST.ASSEMBLY_SUBSTAGE_PAGE:
            navigate('/machine-progress/assembly/sub-stage')
            break;
        case PAGE_CONST.DEFECT_PAGE:
            navigate('/machine-progress/defects')
            break;
        case PAGE_CONST.CREATE_DEFECT_PAGE:
            navigate('/machine-progress/create-defect')
            break;
        case PAGE_CONST.RESOLVE_DEFECT_PAGE:
            navigate(`/machine-progress/resolve-defect/${data.defectId}`)
            break;
        case PAGE_CONST.SUB_STAGE_COMPLETE_PAGE:
            navigate(`/machine-progress/complete/${data.subStageType}/${data.partNumber}`)
            break;
        case PAGE_CONST.ASSEMBLY_CONFIRMATION_SCREEN_PAGE:
            navigate('/machine-progress/confirmation');
            break;
        case PAGE_CONST.MACHINE_CONSENT_PAGE:
            navigate('/machine-progress/assembly/consent');
            break;
        case PAGE_CONST.PRODUCTION_ORDER_LISTING:
            navigate('/production-order-listing');
            break;
        case PAGE_CONST.PRODUCTION_ORDER_DETAIL:
            navigate(`/production-order-detail/${data.orderId}`);
            break;
        case PAGE_CONST.PART_DETAILS:
            navigate(`/machine-detail/${data.orderId}/${data.machineId}`);
            break;
        default:
            break;
    }
}