import SideBar from "../../component/SideBar/SideBar";
import WebHeader from "../../component/WebHeader/WebHeader";
import "./OrderDetailsPage.css";
import CustomSelect from "../../component/Select/Select";
import ReactTable from "../../component/ReactTable/ReactTable";
import { useEffect, useState } from "react";
import { redirect } from "../../common/utils/RedirectionUtils";
import { useNavigate, useParams } from "react-router-dom";
import { PAGE_CONST } from "../../common/constant/CommonConstants";
import arrow_button from "../../common/icons/arrow_button.svg";
import { CustomTable } from "../../component/CustomTable/CustomTable";
import { getOrderMachineDetails } from '../../helper/ProductionOrderHelper';
import "../../component/ReactTable/ReactTable.css";
import moment from "moment";
import { TIMELINE_CONST } from "../../common/constant/CommonConstants";

function OrderDetailsPage(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [orderId, setOrderId] = useState(null);
    const [activeTab, setActiveTab] = useState(PAGE_CONST.MACHINE_DETAILS);
    const [dataContainer, setDataContainer] = useState({});

    useEffect(() => {
        let order_id = params.orderId;
        setOrderId(order_id);
        handleOrderDetailGet(order_id);
    }, [])

    const handleOrderDetailGet = (orderId) => {
        getOrderMachineDetails(orderId).
            then((res) => {
                setDataContainer(res);
                console.log("machine details", res);
            }).catch((err) => {
                console.log(err);
            })
    };

    const handleTypeChange = (page_type) => {
        setActiveTab(page_type);
    };

    const handleMachineRedirection = (orderId, machineId) => {
        // console.log("redirecting to machine :: " + machineId, "with orderId ::", orderId);
        redirect(navigate, PAGE_CONST.PART_DETAILS, { orderId: orderId, machineId: machineId });
    }
    const handleOrdersRedirect = () => {
        console.log("redirecting to polisting");
        redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_LISTING);
    }

    const getOrderTableColumn = () => {
        const columns = [
            {
                title: "MACHINE ID",
                dataIndex: "serial_name",
                key: "machine_id",
                className: "machine_id_class",
                onCell: (record) => ({
                    onClick(e) {
                        if (record.id) {
                            // console.log("record id", record.id);
                            handleMachineRedirection(orderId, record.id);
                        }
                    }
                })
            },
            {
                title: "START DATE",
                dataIndex: "started_at",
                key: "start_date",
                render: date => {
                    if (date)
                        return <div>{moment(date).format(TIMELINE_CONST.DATE_FORMAT)}</div>
                    else return "-";
                }
            },
            {
                title: "END DATE",
                dataIndex: "completed_at",
                key: "end_date",
                render: date => {
                    if (date)
                        return <div>{moment(date).format(TIMELINE_CONST.DATE_FORMAT)}</div>
                    else return "-";
                }
            },
            {
                title: "STATUS",
                dataIndex: "status",
                key: "status",
                className: "status_class",
                render: (row) => {
                    if (row) {
                        return <div className={`${row} status`}>{row.replaceAll('_', " ")}</div>;
                    }
                    else return "-";
                },
            },
            {
                title: "SUB-STATUS",
                dataIndex: "sub_status",
                key: "sub-status",
                className: "sub-status-class",
                render: (row) => {
                    if (row)
                        return <div className="ele-sub-status">{row}</div>;
                    else return "-";
                },
            }
        ];

        return columns;
    };

    const getOrderTableData = () => {
        if (dataContainer.machines) {
            const data = dataContainer.machines;
            return data;
        }
    }

    const getDocumentsTableColumn = () => {
        const columns = [
            {
                title: "DOCUMENT NAME",
                dataIndex: "document_name",
                key: "document_name",
                className: "document_name_class",
            },
            {
                title: "SIZE",
                dataIndex: "document_size",
                key: "document_size",
                className: "document-size-class"
            },
            {
                title: "DATE",
                dataIndex: "date_uploaded",
                key: "date_uploaded",
                className: "upload-date-class"
            },
            {
                title: "ACTIONS",
                dataIndex: "actions",
                key: "actions",
                className: "actions_class",
            },
        ];

        return columns;
    };

    const getDocumentTableData = () => {
        const data = [
            {
                document_name: "Completed Production Order",
                document_size: "3.2 MB",
                date_uploaded: "Today",
                actions: "",
                key: "test-1",
            },
            {
                document_name: "Production Order: Machine ID",
                document_size: "3.2 MB",
                date_uploaded: "23/4/2042",
                actions: "",
                key: "test-2",
            },
            {
                document_name: "Completed Production Order",
                document_size: "3.2 MB",
                date_uploaded: "24/12/2021",
                actions: "",
                key: "test-3",
            },
        ];

        return data;
    };
    if (dataContainer) {
        return (
            <div className="order-details">
                <WebHeader />
                <SideBar />
                <div className="order-listing-body">
                    <div className="order-high-detail-container">
                        <div className="order-details-route">
                            <span className="blue-span" onClick={handleOrdersRedirect}>Production Orders</span>
                            <span>{` > `}</span>
                            <span>{dataContainer.order_id}</span>
                        </div>
                        <div className="order-high-detail">
                            <div className="order-id-heading">
                                <span>{dataContainer.order_id}</span>
                            </div>
                            <div className={`status ${dataContainer.status}`}>{dataContainer.status}</div>
                        </div>
                    </div>
                    <div className="order-details-listing-body">
                        <div className="order-details-header">
                            <span>Order Details</span>
                        </div>
                        <div className="header-details-container">
                            <div className="machine-type-header-container common-container">
                                <div className="machine-type common-heading"> Machine Type</div>
                                <div className="show-machine">{dataContainer.product_name}</div>
                            </div>
                            <div className="number-of-machines-container common-container">
                                <div className="number-of-machines-flex common-heading">
                                    Number of Machines
                                </div>
                                <div className="show-num-machine">{dataContainer.num_of_machines}</div>
                            </div>
                            <div className="date-container common-container">
                                <div className="date-of-order common-heading"> Date of Order</div>
                                <div className="show-date">
                                    {moment(dataContainer.created_at).format(TIMELINE_CONST.DATE_FORMAT)}</div>
                            </div>
                        </div>
                        <div className="page-toggle-container">
                            <div
                                className={activeTab === PAGE_CONST.MACHINE_DETAILS ?
                                    "common-toggle-class toggle-active" :
                                    "common-toggle-class"}
                                onClick={() => {
                                    handleTypeChange(PAGE_CONST.MACHINE_DETAILS);
                                }}
                            >
                                Machine Details
                            </div>
                            <div
                                className={activeTab === PAGE_CONST.DOCUMENT_UPLOAD ?
                                    "common-toggle-class toggle-active" :
                                    "common-toggle-class"}
                                onClick={() => {
                                    handleTypeChange(PAGE_CONST.DOCUMENT_UPLOAD);
                                }}
                            >
                                Documents
                            </div>
                            <div className="hidden-div"></div>
                            <div className="arrow-container ">
                                <img className="arrow-button" src={arrow_button} />
                            </div>
                        </div>
                        {activeTab === PAGE_CONST.MACHINE_DETAILS && (
                            <div className="react-table-container">
                                <ReactTable
                                    columns={getOrderTableColumn()}
                                    data={getOrderTableData()}
                                    rowKey="serial_name"
                                />
                            </div>
                        )}
                        {activeTab === PAGE_CONST.DOCUMENT_UPLOAD && (
                            <div className="custom-table-container">
                                <CustomTable
                                    columnData={getDocumentsTableColumn()}
                                    rowData={getDocumentTableData()}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}




export default OrderDetailsPage;
