import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './AssemblyConfirmationScreen.css';
import SubHeader from '../SubHeader/SubHeader';
import { redirect } from '../../common/utils/RedirectionUtils';
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import { connect } from 'react-redux';
import CreationFactory from '../../helper/CreationFactory';

function AssemblyConfirmationScreen(props) {

    const params = useParams();
    const navigate = useNavigate();
    const [machineId, setMachineId] = useState(null);
    const [machineName, setMachineName] = useState(null);
    const [stageId, setStageId] = useState(null);
    const [stageData, setStageData] = useState(null);
    const [partNumber, setPartNumber] = useState(null);

    let _creationFactory = new CreationFactory();
    useEffect(() => {
        let mId = props.machineData.id;
        let mName = props.machineData.name;
        let stageId = props.activeData.stage.id;
        
        setMachineId(mId);
        setMachineName(mName);
        setStageId(stageId);

        let data = {
            machineId: mId,
            stageId: stageId
        }

        _creationFactory.getStageDetails(data)
            .then((res) => {
                setStageData(res); 

                let length = res.sub_stage_data.length;
                setPartNumber(length);
            });
    }, []);

    const handleAssemblyCompleteClick = () => {
        
        let data = {
            stageId,
            status: "COMPLETED"
        }
        _creationFactory.updateStageStatus(data)
            .then(() => {
                let routeData = {
                    subStageType: 'assemblyComplete',
                    partNumber: partNumber
                }

                redirect(navigate, PAGE_CONST.SUB_STAGE_COMPLETE_PAGE, routeData);
                // navigate(`/machine-progress/complete/assemblyComplete/${partNumber}`);
            })
            .catch((err) => {
                console.log("error occured")
            })
    }

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    }

    const handleMachineTimeLineClick = () => {
        redirect(navigate, PAGE_CONST.ASSEMBLY_TIMELINE_PAGE);
    }

    const handleDefectClick = () => {
        redirect(navigate, PAGE_CONST.DEFECT_PAGE);
        // navigate(`/machine-progress/${machineId}/defects`);
    }

    const getPartHtml = () => {

        let html = stageData.sub_stage_data.map((component) => {

            return (
                <div className="part-details" key={component.component_name}>
                    <div className="part-info-name">
                        {component.component_label}
                    </div>
                    <div className="part-info-value">
                        {component.component_name}
                    </div>
                </div>
            )
        })

        return html;
    }

    return (
        <>
            {stageData && <div className="assembly-comfirmation">
                <SubHeader
                    handleDefectClick={handleDefectClick}
                    handleMachineTimeLineClick={handleMachineTimeLineClick}
                    handleBackClick={handleBackClick}
                    machineName={machineName}
                    showDefects={true}
                    showTimeline={true}
                    showBackButton={true}
                />

                <div className="assembly-comfirmation-body">
                    <div className="part-number-info">
                        <div>Parts</div>
                        <span className="number">{partNumber} Parts for Product {machineName} are assembled.</span>
                    </div>
                    <div className="part-detail-info">

                        {getPartHtml()}
                    </div>
                    <div className="assembly-comfirmation-save-button-container">
                        <button className="save-button" 
                            onClick={() => handleAssemblyCompleteClick()} >Machine Assembly Complete</button>
                    </div>  
                </div>
            </div>}
        </>
  );
}

const mapStateToProps = state => {

    return {
        machineData: state.machineDataReducer.machineData,
        activeData: state.machineActiveDataReducer.activeData
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
        
//     }
// };

export default connect(mapStateToProps, null)(AssemblyConfirmationScreen);