import { MachineCreationFactory } from "../factory/MachineCreationFactory";
import { SuperComponentCreationFactory } from "../factory/SuperComponentCreationFactory";
import { CREATION_CONSTANTS } from "../../common/constant/FactoryConstants";

class CreationFactoryMapper {
    constructor () {
        const superComponentCreationFactory = new SuperComponentCreationFactory();
        const machineCreationFactory = new MachineCreationFactory();

        this.factories = {};
        this.factories[CREATION_CONSTANTS.SUPER_COMPONENT] = superComponentCreationFactory;
        this.factories[CREATION_CONSTANTS.MACHINE] = machineCreationFactory;
    }

    factory = type => type && this.factories[type];
}

export default CreationFactoryMapper;