import { logoutAPI, sendOtpAPI, verifyOtpAPI } from "../adapter/machineCreationAdapter";
import { LOGIN_CONST } from "../common/constant/CommonConstants";
import { getCookieCustom } from "../common/utils/StorageUtils";


export const sendOtp = (data) => {
    let reqData = {
        sign_in_type: data.loginType,
        value: data.value
    }

    let promise = new Promise(function(resolve, reject) {
        sendOtpAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200 && res.data.data.is_otp_sent) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const verifyOtp = (data) => {
    let reqData = {
        sign_in_type: data.loginType,
        value: data.value,
        otp: data.otp
    }

    let promise = new Promise(function(resolve, reject) {
        verifyOtpAPI(reqData).then((res) => {
            // console.log("verify otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data)
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const logout = (data) => {
    let reqData = {
        refresh_token: getCookieCustom(LOGIN_CONST.REFRESH_TOKEN)
    }

    let promise = new Promise(function(resolve, reject) {
        logoutAPI(reqData).then((res) => {

            if (res && res.data && res.data.status === 200) {

                // deleteCookieCustom(LOGIN_CONST.REFRESH_TOKEN);
                // deleteCookieCustom(LOGIN_CONST.ACCESS_TOKEN);
                // deleteCookieCustom(LOGIN_CONST.USER_DETAIL);

                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}