import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './MachineRegistrationPage.css';
import CustomSelect from '../../component/Select/Select';
import QRScanner from '../../component/QrScanner/QrScanner';
import SubHeader from '../../component/SubHeader/SubHeader';
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import { redirect } from '../../common/utils/RedirectionUtils';
import { setMachineDetailsAction } from '../../modules/actions/action';
import { connect } from 'react-redux';
import CreationFactory from '../../helper/CreationFactory';
import { CREATION_CONSTANTS } from '../../common/constant/FactoryConstants';
import { setCookieCustom, getCookieCustom } from '../../common/utils/StorageUtils';

function MachineRegistrationPage(props) {

    const TYPE = "type";
    const SCAN = "scan";

    const navigate = useNavigate();
    const [pageType, setPageType] = useState(null);
    const [machineType, setMachineType] = useState([]);
    const [selectedMachineType, setSelectedMachineType] = useState(null);
    const [machineName, setMachineName] = useState('');
    const [dataFlag, setDataFlag] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [creationType, setCreationType] = useState(CREATION_CONSTANTS.MACHINE);

    let _creationFactory = new CreationFactory();

    useEffect(() => {
        checkDataStatus(machineName, selectedMachineType);
    }, [machineName, selectedMachineType, machineType]);

    useEffect(() => {
        const existingFlowType = getCookieCustom(CREATION_CONSTANTS.FLOW_TYPE) || CREATION_CONSTANTS.MACHINE;
        setCreationType(existingFlowType);
    }, [])

    useEffect(() => {

        const type = new URLSearchParams(window.location.search).get(TYPE);
        setPageType(type);

        if (!pageType || pageType !== SCAN) {
            _creationFactory.getAllType(null, creationType)
                .then((res) => {
                    setMachineType(res);
                });
        }

        return function cleanup() {
        };
    }, [creationType]);

    const checkDataStatus = (mId, mType) => {

        let dataFlag = false;

        if (mId) {
            if (pageType === SCAN) dataFlag = true;
            else if (mType) {
                dataFlag = true;
            }
        }

        setDataFlag(dataFlag);
    };

    const handleSelectChange = (item) => {
        setSelectedMachineType((item && item.id) || null);
    }

    const handleScan = (result) => {

        if (errorMsg) setErrorMsg(null);
        setMachineName(result);
    }

    const handleSave = () => {
        if (dataFlag) {
            setCookieCustom(CREATION_CONSTANTS.FLOW_TYPE, creationType);
            if (pageType === SCAN) {
                _creationFactory.getProgressDetails(machineName, creationType)
                    .then((data) => {
                        props.setMachineDetailsAction(data);
                        if (data.id) redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE)
                    }).catch((error) => {
                        setErrorMsg(error);
                    });
            } else {
                let data = {
                    machineName,
                    selectedMachineType
                }
                _creationFactory.create(data, creationType)
                    .then((res) => {
                        props.setMachineDetailsAction(res);
                        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE)
                        // setLocalStorageCustom(MACHINE_CONST.SELECTED_MACHINE_TYPE, selectedMachineType);
                    }).catch((err) => {
                        // if (err.code === "MACHINE_ALREADY_EXIST") {
                        setErrorMsg(err.message);
                        // redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
                        // }
                        // setErrorMsg(err);
                    });
            }
        }
    }

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.HOME_PAGE);
        // navigate('/home-page');
    }

    const handleCreationTypeClick = (type) => {
        setCreationType(type);
        setMachineType(null);
        // setMachineName('');
    }

    const getBodyHeaderText = () => {

        if (pageType === SCAN) {
            return (creationType === CREATION_CONSTANTS.MACHINE ? 'Scan Machine ID' : 'Scan SubAssembly ID');
        } else {
            return (creationType === CREATION_CONSTANTS.MACHINE ? 'Machine Registration' : 'Sub-Assembly Registration');
        }
    }

    return (
        <div className="machine-registration">
            <SubHeader
                handleBackClick={handleBackClick}
                machineName={'MainLine Production'}
                showDefects={false}
                showTimeline={false}
                showBackButton={true}
            />
            <div className="machine-registration-body">

                <div className="creation-checkbox-container">
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            name={CREATION_CONSTANTS.MACHINE}
                            className="checkbox-round"
                            checked={creationType === CREATION_CONSTANTS.MACHINE}
                            onChange={() => handleCreationTypeClick(CREATION_CONSTANTS.MACHINE)} />
                        <span className="creation-checkbox-text">Machine</span>
                    </div>
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            name={CREATION_CONSTANTS.SUPER_COMPONENT}
                            className="checkbox-round"
                            checked={creationType === CREATION_CONSTANTS.SUPER_COMPONENT}
                            onChange={() => handleCreationTypeClick(CREATION_CONSTANTS.SUPER_COMPONENT)} />
                        <span className="creation-checkbox-text">Sub-Assembly</span>
                    </div>
                </div>
                <div className="mrb-item machine-registration-body-header">
                    {getBodyHeaderText()}
                </div>
                {(!pageType || pageType !== SCAN) && <div className="mrb-item mrb-mr">
                    <CustomSelect
                        id="select-machine-type"
                        handleChange={(item) => handleSelectChange(item)}
                        options={machineType}
                        placeholder={creationType === CREATION_CONSTANTS.MACHINE ?
                            'Machine Type' : 'Sub Assembly Type'}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                    />
                </div>}
                <div className="mrb-item mrb-mr input-container">
                    <QRScanner
                        placeholder={creationType === CREATION_CONSTANTS.MACHINE ?
                            'Scan or Enter Machine Id' : 'Scan or Enter Sub Assembly Id'}
                        handleScan={(result) => handleScan(result)}
                        value={machineName}
                    />
                </div>
                {errorMsg && <div className="error"> {errorMsg}</div>}
                <div className="save-button-container">
                    <button className={dataFlag ? "registration-save-button" : "registration-save-button button-disabled"} onClick={handleSave} >Save</button>
                    <div className="save-text">
                        The parts will get registered against this Product ID
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        machineData: state.machineDataReducer.machineData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMachineDetailsAction: (payload) => dispatch(setMachineDetailsAction(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineRegistrationPage);