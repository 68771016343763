import React, { useEffect, useState } from 'react';
import { useNavigate, withRouter, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { PAGE_CONST } from '../../common/constant/CommonConstants';

import DefectDetails from '../../component/DefectDetails/DefectDetails';
import QRScanner from '../../component/QrScanner/QrScanner';
import CustomSelect from '../../component/Select/Select';
import SubHeader from '../../component/SubHeader/SubHeader';

import "./ResolveDefect.css";
import { redirect } from '../../common/utils/RedirectionUtils';
import CreationFactory from '../../helper/CreationFactory';
import DefectFactory from '../../helper/DefectFactory';

function ResolveDefect(props) {
    const params = useParams();
    const navigate = useNavigate();
    let _creationFactory = new CreationFactory();
    let _defectFactory = new DefectFactory();

    const [machineId, setMachineId] = useState('');
    const [machineName, setMachineName] = useState('');
    const [defectId, setDefectId] = useState('');
    const [defectDescription, setDefectDescription] = useState(null);
    const [partTypeOption, setPartTypeOption] = useState(null);
    const [defectType, setDefectType] = useState(null);
    const [dataFlag, setDataFlag] = useState(false);
    const [defectedPartArray, setDefectedPartArray] = useState([]);
    const [emptyInputFlag, setEmptyInputFlag] = useState(false);
    const [defectData, setDefectData] = useState([]);
    const [defectResolutionOption, setDefectResolutionOption] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    const ASSOCIATED_PART = "Associated_Part";
    const DEFECT_TYPE = "Defect_Type";
    const DEFECT_SOLUTION_PLACEHOLDER = "Defect Solution";
    const PART_NEEDS_TO_BE_REPLACED = "PART_NEEDS_TO_BE_REPLACED";
    const SELECT_PART_PLACEHOLDER = "Select Part";

    useEffect(() => {
        let mId = props.machineData.id;
        let defectId = params.defectId;
        setMachineId(mId);
        setMachineName(props.machineData.name);
        setDefectId(defectId);

        let reqData = {
            machineId: mId
        }
        _creationFactory.getComponents(reqData)
            .then((res) => {

                if (res) setPartTypeOption(res);
            })
            .catch((err) => {
                console.log(err);
            });
            
        _defectFactory.getDefectById({defectId: defectId})
        .then((res) => {
            setDefectData(res);

            // if (res.machine_name) setMachineName(res.machine_name);
        })
        .catch((err) => {
            console.log("error occured in getAllDefect :: " + JSON.stringify(err));
        })

        // let selectedMachineId = getLocalStorageCustom(MACHINE_CONST.SELECTED_MACHINE_TYPE);

        let selectedMachineId = props.machineData.product_id;
        _defectFactory.getDefectResolutionTypes(selectedMachineId)
        .then((data) => {
            if (data) setDefectResolutionOption(data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        handleDataCompletion();
        setErrorMsg('');
    }, [defectType, defectDescription, emptyInputFlag, defectedPartArray]);

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.DEFECT_PAGE);
    }

    const handleSave = () => {

        if (dataFlag) {
            let data = {
                defectId: defectId,
                defectTypeId: defectType.id,
                defectDescription: defectDescription,
                defectedPartArray: defectedPartArray
            }

            _defectFactory.resolveDefect(data)
            .then((res) => {
                redirect(navigate, PAGE_CONST.DEFECT_PAGE);
                // navigate(`/machine-progress/${machineId}/defects`);
            })
            .catch((err) => {
                setErrorMsg(err);
            })
        }
    }

    const handleInputChange = (event) => {
        setDataFlag(false);
        let value = event && event.target && event.target.value;

        if (value) {
            setDefectDescription(value);
        }
    }

    const handleSelectChange = (inputType, selectedItem, oldItem) => {
        setDataFlag(false);
        if (inputType === ASSOCIATED_PART) {
            console.log("selectedItem: " + JSON.stringify(selectedItem));
            // let defectedArray = defectedPartArray;

            if (!defectedPartArray.includes(selectedItem)) {

                if (oldItem) {
                    oldItem.new_id = null;
                    let index = defectedPartArray.findIndex((obj => obj.id == oldItem.id));
                    defectedPartArray[index] = selectedItem;
                } else {
                    defectedPartArray.push(selectedItem);
                }
            }

            setDefectedPartArray([...defectedPartArray]);
            setEmptyInputFlag(false);
            
        } else if (inputType === DEFECT_TYPE) {

            if (selectedItem && selectedItem.name === PART_NEEDS_TO_BE_REPLACED) {
                setEmptyInputFlag(true);
            } else {
                setDefectedPartArray([]);
            }
            console.log("selectedItem d: " + selectedItem);
            setDefectType(selectedItem);
        }
    }

    const addNewComponentId = (item, id) => {

        let array = defectedPartArray;
        for (let i=0; i < array.length; i++) {
            let arrItem = array[i];
            if (item.id === arrItem.id) {
                arrItem.new_id = id;
            }
        }

        setDefectedPartArray([...array]);
        handleDataCompletion();
    }

    const handleDataCompletion = () => {
        setDataFlag(false);

        let dataFlag = false

        if (defectDescription && defectType) {
            if (defectType.name !== PART_NEEDS_TO_BE_REPLACED) {

                dataFlag = true;
            } else {
                if (defectedPartArray.length > 0) {
                    if (defectedPartArray.filter((defectedPart) => !defectedPart.new_id).length <= 0) dataFlag = true;
                }
            }
        }

        setDataFlag(dataFlag);
    }

    const getDefectedDetailHtml = () => {

        let html = '';
        if (defectedPartArray.length !== 0) {
            html = defectedPartArray.map((item, index) => {
                return (
                    <div className="defected-part">
                        <div className="defected-part-number">Part {index + 1}</div>
                        <div className="resolve-select-container" key={item.id}>
                            <CustomSelect
                                id="select-component-type"
                                handleChange={(selectedItem) => handleSelectChange(ASSOCIATED_PART, selectedItem, item)}
                                value = {item}
                                label = 'component_type_label'
                                options={partTypeOption}
                                placeholder = {SELECT_PART_PLACEHOLDER}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        <div className="resolve-select-container defected input-container" key={item.component_id}>
                            <QRScanner value={item.new_id} placeholder={"Part ID"} handleScan={(result) => addNewComponentId(item, result)}/>
                        </div>
                    </div>
                );
            });
        };

        return html;
    }
    
    const getEmptyComponentDetailBox = () => {
        if (emptyInputFlag) {
            return (
                <>
                    <div className="resolve-select-container">
                        <CustomSelect
                            id="empty-component"
                            handleChange={(item) => handleSelectChange(ASSOCIATED_PART, item)}
                            label = 'component_type_label'
                            options={partTypeOption}
                            placeholder= {SELECT_PART_PLACEHOLDER}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="resolve-select-container input-container">
                        <QRScanner placeholder={"Part ID"} handleScan={(result) => console.log("result: " + result)}/>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <div className="resolve-defect">
                <SubHeader 
                    handleBackClick={handleBackClick}
                    machineName={'Resolve Defect'}
                    showDefects={false}
                    showTimeline={false}
                    showBackButton={true}
                />
                <div className="resolve-defect-body">
                    <div className="resolve-defect-body-header">
                        <div className="defect-body-heading-title">
                            {machineName}
                        </div>
                    </div>
                    <DefectDetails resolveTag={false} data={defectData} />
                    <div className="resolve-defect-body-body">
                        <div className="resolve-select-container">
                            <CustomSelect
                                id="select-defect-solution"
                                handleChange={(item) => handleSelectChange(DEFECT_TYPE, item)}
                                options={defectResolutionOption}
                                label = 'label'
                                placeholder={DEFECT_SOLUTION_PLACEHOLDER}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        {getDefectedDetailHtml()}
                        {
                            defectType && defectType.name === PART_NEEDS_TO_BE_REPLACED && 
                            <>
                                {getEmptyComponentDetailBox()}
                                <div className="add-part-container">
                                    <div className="add-part" onClick={() => {
                                        console.log(emptyInputFlag);
                                        setEmptyInputFlag(true)
                                    }}>
                                        + Add Part
                                    </div>
                                </div>
                            </>
                        }
                        <div className="resolve-defect-input-container">
                            <textarea className="defect-details-input-box" placeholder="Description"
                                onChange={(event) => handleInputChange(event)}
                            />
                        </div>
                        {errorMsg && <div className="error-msg">
                            {errorMsg}
                        </div>}
                    </div>
                    <div className="resolve-defect-body-save-button-container">
                        <button className={dataFlag ? "defect-save-button" : "substage-save-button button-disabled"} 
                            onClick={handleSave} >Submit</button>
                    </div>  
                </div>
            </div>
        </>
  );
}

const mapStateToProps = state => {

    return {
        machineData: state.machineDataReducer.machineData
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         
//     }
// };

export default connect(mapStateToProps, null)(ResolveDefect);