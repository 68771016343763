import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import neev_logo from '../../common/icons/neev_logo.svg';
import ham_burger from '../../common/icons/ham_burger.svg';
import dms_svg from '../../common/icons/dms.svg';
import settings_svg from '../../common/icons/settings.svg';
import sign_out_svg from '../../common/icons/sign_out.svg';

import './HomePage.css';
import { blurApp, unBlurApp } from '../../common/utils/CommonUtils';
import { logout } from '../../helper/LoginHelper';
import { PAGE_CONST } from '../../common/constant/CommonConstants';

import { connect } from 'react-redux';
import { redirect } from '../../common/utils/RedirectionUtils';

function HomePage(props) {

    const ADDITION = "addition";
    const SCAN = "scan";
    const navigate = useNavigate();

    const [isHamOpen, _setIsHamOpen] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    const isHamOpenRef = useRef(isHamOpen);
    const setIsHamOpen = data => {
        isHamOpenRef.current = data;
        _setIsHamOpen(data);
    };

    const handleAddMachine = (type) => {
        switch (type) {
            case ADDITION:
                redirect(navigate, PAGE_CONST.MACHINE_REGISTRATION_PAGE)
                break;
            case SCAN:
                redirect(navigate, PAGE_CONST.MACHINE_REGISTRATION_PAGE_SCAN)
                // navigate('/machine-creation/?type=scan');
                break;
        }
    }

    useEffect(() => {
        // save user details to state

        let userDetails = props.userDetails;

        if (userDetails) setUserDetails(userDetails);
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleHamBurgerOpen);

        return () => {
            document.removeEventListener('click', handleHamBurgerOpen);
        }
    }, [isHamOpen]);

    const handleHamBurgerOpen = (e) => {
        let classList = e.target && e.target.classList;
        let offSetParentClass = (e.target.offsetParent && e.target.offsetParent.className) || '';
        // console.log(e.target.offsetParent);
        if (classList && classList.length > 0) {
            let currentClass = classList[0];

            if (currentClass === "hamburger-logo-img" && !isHamOpenRef.current) {
                setIsHamOpen(true);
                blurApp();
            } else if (isHamOpenRef.current && (offSetParentClass !== 'sidebar-container' && currentClass !== "sidebar-container")) {
                setIsHamOpen(false);
                unBlurApp();
            }
        }
    }

    const handleHamClick = () => {
        // setIsHamOpen(true); 
    }

    const handleHamOnBlur = () => {
        console.log(" blur !!!!!!!!!!!!!!!!!");
    }

    const handleSignOutClick = () => {
        logout()
            .then((res) => {
                unBlurApp();
                redirect(navigate, PAGE_CONST.LOGIN_PAGE);
            }).catch((err) => {
                console.log("err:: " + err);
            });
    }

    return (
        <>
            {/* <div className="side-bar" id="side-bar"> */}
            {isHamOpen && <div className="sidebar-container" id="sidebar-container" onBlur={handleHamOnBlur}>
                <div className="user-details-container">
                    <div className="user-name">
                        {(userDetails && userDetails.name) || 'User Name'}
                    </div>
                    <div className="user-email">
                        {(userDetails && userDetails.email) || 'Email'}
                    </div>
                </div>
                <div className="link-container">
                    <div className="link">
                        <img className="link-icon" src={dms_svg} alt="Link Icon" />
                        DMS
                    </div>
                    <div className="link">
                        <img className="link-icon" src={settings_svg} alt="Link Icon" />
                        Settings
                    </div>
                </div>
                <div className="sign-out-container" onClick={() => handleSignOutClick()}>
                    <img className="link-icon" src={sign_out_svg} alt="Link Icon" />
                    Sign Out
                </div>
            </div>}
            {/* </div> */}
            <div className={"home-page"}>
                <div className="hamburger-logo-container">
                    <img className="hamburger-logo-img" src={ham_burger} alt="App Logo" onClick={handleHamClick} />
                </div>
                <div className="homepage-logo-container">
                    <img className="home-logo-img" src={neev_logo} alt="App Logo" />
                </div>
                <div className="add-machine-container">
                    <button
                        className={"save-button"}
                        onClick={() => handleAddMachine(ADDITION)}>
                        Add
                    </button>
                </div>
                <div className="add-machine-container">
                    <button
                        className={"scan-machine"}
                        onClick={() => handleAddMachine(SCAN)}>
                        Scan
                    </button>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {

    return {
        userDetails: state.userDetailsReducer.userDetail
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
