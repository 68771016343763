import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';

import "./MachineProgress.css";
import sucess_tick_green from '../../common/icons/sucess_tick_green.svg';
import { setMachineDetailsAction, setActiveSubStageAction } from '../../modules/actions/action';
import SubHeader from '../../component/SubHeader/SubHeader';
import { redirect } from '../../common/utils/RedirectionUtils';
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import CreationFactory from '../../helper/CreationFactory';

function MachineProgress(props) {
    const navigate = useNavigate();
    const [machineId, setMachineId] = useState(null);
    const [activeStageId, setActiveStageId] = useState(null);
    const [activeSubStageId, setActiveSubStageId] = useState(null);
    const [isAllStagesCompleted, setIsAllStagesCompleted] = useState(true);
    const [pendingDefect, setPendingDefect] = useState(false);
    const [ispendingDefectError, setIsPendingDefectError] = useState(false);

    let _creationFactory = new CreationFactory();

    useEffect(() => {

        _creationFactory.getProgressDetails(props.machineData.name)
            .then((data) => {
                setActiveStageId(null);
                setActiveSubStageId(null);
                if (data.id) setMachineId(data.id);

                if (data && data.pending_defects && data.pending_defects > 0) setPendingDefect(true);
                props.setMachineDetailsAction(data);
            });
    }, []);

    useEffect(() => {
        window.addEventListener("popstate", () => handleBackClick());
        return () => window.removeEventListener("popstate", () => handleBackClick());
    }, [onpopstate]);

    useEffect(() => {
        markStageActive(props.machineData);
    }, [props.machineData, props])

    const markStageActive = (data) => {
        let tempActiveStageId = activeStageId;
        let tempActiveSubStageId = activeSubStageId;

        let isAllStageComplete = true;
        if (data && data.stages && !tempActiveStageId) {
            data.stages.map((stage) => {

                if (data.status === "COMPLETED" || stage.status !== "COMPLETED") {
                    isAllStageComplete = false;
                }
                if (!tempActiveStageId && (stage.status == 'NOT_STARTED' || stage.status == "IN_PROGRESS")) {
                    tempActiveStageId = stage.id;
                    setActiveStageId(tempActiveStageId);

                    stage.substages.map((subStage) => {
                        if (!tempActiveSubStageId && (subStage.status == "NOT_STARTED" || subStage.status == "IN_PROGRESS")) {
                            tempActiveSubStageId = subStage.id;
                            setActiveSubStageId(subStage.id);
                        }
                    })
                }
            })

            setIsAllStagesCompleted(isAllStageComplete);
        }
    }

    const handleSubStageClick = (stage, subStage) => {

        if (pendingDefect) {
            setIsPendingDefectError(true);
        } else {

            let data = {
                state: stage,
                subStage: subStage
            }

            props.setActiveSubStageAction(data);

            // console.log("stage:: " + JSON.stringify(stage));
            // console.log(" subStage:: " + JSON.stringify(subStage));
            redirect(navigate, PAGE_CONST.ASSEMBLY_SUBSTAGE_PAGE);
            // navigate(`/machine-progress/assembly/${machineId}/stage/${stage.id}/sub-stage/${subStage.id}`);
        }
    }

    const handleMachineAssemblyClick = (stage) => {

        let data = {
            state: stage,
            subStage: {}
        }

        props.setActiveSubStageAction(data);
        redirect(navigate, PAGE_CONST.ASSEMBLY_CONFIRMATION_SCREEN_PAGE);
        // navigate(`/machine-progress/confirmation/${machineId}/${props.machineData.name}/${stageId}`);
    }

    const handleConsentClick = () => {

        if (isAllStagesCompleted) {
            redirect(navigate, PAGE_CONST.MACHINE_CONSENT_PAGE);
            // navigate(`/machine-progress/assembly/consent/${machineId}/${props.machineData.name}`);
        }
    }

    const getStateHtml = (machineData) => {

        let html;
        let isMachineAssemblyButtonEnabled = true;
        if (!machineData || !machineData.stages) {
            // html = `Machine Data does not exist!! `;
            html = '';
        } else {

            html = machineData.stages.map((stage, index) => {
                return (
                    <div key={stage.id} className={((stage.id === activeStageId) || stage.status === 'COMPLETED')
                        ? "machine-stage-details active-stage" : "machine-stage-details inactive-stage"}>
                        <div className="stage-name-container">
                            <span className={stage.id === activeStageId ? "machine-stage-name mb-2" : "machine-stage-name"}>
                                {stage.name}
                            </span>
                            {stage.status === 'COMPLETED' && <span className="stage-completed">
                                Completed
                            </span>}
                        </div>
                        {(ispendingDefectError && (stage.id === activeStageId)) &&
                            <div className="error-msg">Pending Defects in this stage, resolve defects to continue</div>}
                        {
                            ((stage.id === activeStageId) && (stage.status !== "COMPLETED")) &&
                            <>
                                {
                                    stage.substages && stage.substages.map((substage) => {

                                        if (['Mainline Production', 'Part Assembly'].includes(stage.name) && substage.status !== "COMPLETED") {
                                            isMachineAssemblyButtonEnabled = false;
                                        }
                                        return (
                                            <div key={substage.id} className={"sub-stage substage-button-container"}>
                                                <button className={
                                                    ((substage.id === activeSubStageId || !stage.is_sequence_required) && substage.status !== 'COMPLETED') ?
                                                        "sub-station-button active-button" : "sub-station-button no-pointer-events"}
                                                    onClick={() => { handleSubStageClick(stage, substage) }}>
                                                    <span className="button-text">{substage.name}</span>
                                                    {substage.status === 'COMPLETED' &&
                                                        <img className="button-icon" src={sucess_tick_green} alt="success svg" />}
                                                </button>
                                            </div>
                                        );
                                    })
                                }
                                {['Mainline Production', 'Part Assembly'].includes(stage.name) &&
                                    <div key={stage.id} className="machine-assembly-completion substage-button-container">
                                        <button className={isMachineAssemblyButtonEnabled ? "assembly-completion-button" :
                                            "assembly-completion-button button-disabled"}
                                            onClick={() => handleMachineAssemblyClick(stage)}>Machine Assembled</button>
                                    </div>}
                            </>
                        }
                    </div>);
            });
        }

        return html;
    }

    const handleDefectClick = () => {
        redirect(navigate, PAGE_CONST.DEFECT_PAGE);
    }

    const handleBackClick = () => {
        navigate('/machine-creation');
    }

    const handleMachineTimeLineClick = () => {
        redirect(navigate, PAGE_CONST.ASSEMBLY_TIMELINE_PAGE);
    }

    return (
        <div className="machine-progress">
            <SubHeader
                handleMachineTimeLineClick={handleMachineTimeLineClick}
                handleBackClick={handleBackClick}
                machineName={props.machineData.name}
                showDefects={props.machineData.status === "COMPLETED" ? false : true}
                showTimeline={true}
                showBackButton={true}
                pendingDefect={pendingDefect}
            />
            <div className="machine-progress-body">
                {getStateHtml(props.machineData)}
            </div>

            {(props.machineData && props.machineData.stages) && <div className="machine-completion-button-container">
                <button className={(isAllStagesCompleted && !pendingDefect) ? "machine-completion-button" : "machine-completion-button button-disabled"}
                    onClick={() => handleConsentClick()}>
                    {props.machineData.status === 'COMPLETED' ? 'Machine Assembly Completed' : 'Consent and Complete'}
                </button>
            </div>}
        </div>
    );
}

const mapStateToProps = state => {

    return {
        machineData: state.machineDataReducer.machineData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMachineDetailsAction: (payload) => dispatch(setMachineDetailsAction(payload)),
        setActiveSubStageAction: (payload) => dispatch(setActiveSubStageAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineProgress);