import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {reducer} from "../reducer/reducer";
import initialState from "./initialState";
// import logger from 'redux-logger'
// import thunk from "redux-thunk";
// import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
  key: 'neev-root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

// const composeEnhancer = composeWithDevTools || compose
// const middleWare = [thunk, logger];

export const store = createStore(persistedReducer, initialState);
export const persistor = persistStore(store);




// export const store = createStore(
//   reducer, composeEnhancer(applyMiddleware(...middleWare)));

  // export default () => {
  //   let store = createStore(persistedReducer)
  //   let persistor = persistStore(store)
  //   return { store, persistor }
  // }