import Table from 'rc-table';
import "rc-table/assets/index.css";
import { useNavigate } from "react-router-dom"; 
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import { redirect } from '../../common/utils/RedirectionUtils';
import "./ReactTable.css";

function ReactTable(props) {

    const navigate = useNavigate();
    
    return (
        <Table columns={props.columns} data={props.data} rowKey={props.rowKey}/>
    );
}

export default ReactTable;
