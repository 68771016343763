import React from 'react';
import "./SideBar.css";
import dms_svg from "../../common/icons/dms.svg";
import logo_svg from "../../common/icons/logo.svg";
import horizontal_line_svg from "../../common/icons/horizontal_lines.svg";
import people_svg from "../../common/icons/people.svg";

function SideBar() {
    return (
        <div className="web-sidebar">
            <div className="web-sidebar-icons-container">
                <div className="logo-icon web-sidebar-icon">
                    <img className="sidebar-svg" src={logo_svg} alt="Link Icon" />
                </div>
                <div className="horizontal_line web-sidebar-icon">
                    <img className="sidebar-svg" src={horizontal_line_svg} alt="Link Icon" />
                </div>
                <div className="people web-sidebar-icon">
                    <img className="sidebar-svg" src={people_svg} alt="Link Icon" />
                </div>
            </div>
        </div>
    );
}

export default SideBar;