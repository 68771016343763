import React, {useState} from 'react';
import Select from 'react-select';
import { handleOnFocusById } from '../../common/utils/CommonUtils';

export default function CustomSelect (props) {

    const [data, setData] = useState(null);

    const customStyles = {
        container: (base) => ({
            ...base,
            height: '100%',
            minWidth: (props.style && props.style.containerWidth) || '50px',
        }),
        control: (base) => ({
            ...base,
            height: '100%',
            minHeight: (props.style && props.style.controlHeight) || '50px',
            minWidth: (props.style && props.style.controlMinWidth) || '',
            borderColor: '#D5D8DD'
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: (props.style && props.style.fontSize) || '18px',
            // color: '#00173D'
        }),
        menu: (base) => ({
            ...base,
            marginTop: '0px',
            marginBottom: '0px',
            backgroundColor: '#F0F5FF',
        }),
        option: (base) => ({
            ...base,
            padding: (props.style && props.style.optionPadding) || '4%',
            fontSize: '16px',
        }),
        singleValue: (base) => ({
            ...base,
            fontSize: '18px',
        })
    }

    const handleChange = (item) => {        
        setData(item);

        props.handleChange(item);
    }

    return (
            <Select
                id={props.id}
                value={props.hasOwnProperty('value') ? props.value : data}
                onChange={handleChange}
                isClearable={true}
                options={props.options || []}
                getOptionValue={(option) => option[props['label']] || option.name}
                getOptionLabel={(option) => option[props['label']] || option.name}
                placeholder={props.placeholder}
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null
                }}
                onFocus={() => {
                    handleOnFocusById(`${props.id || props.placeholder}`)
                }}
            />
    )
}


// behavior Optional
// Defines the transition animation. One of auto or smooth. Defaults to auto.

// block Optional
// Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.

// inline Optional
// Defines horizontal alignment. One of start, center, end, or nearest. Defaults to nearest.