import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import { handleOnFocusById } from '../../common/utils/CommonUtils';
import CustomSelect from '../../component/Select/Select';
import SubHeader from '../../component/SubHeader/SubHeader';
import { connect } from 'react-redux';

import "./CreateDefect.css";
import { redirect } from '../../common/utils/RedirectionUtils';
import CreationFactory from '../../helper/CreationFactory';
import DefectFactory from '../../helper/DefectFactory';

function CreateDefect(props) {
    const params = useParams();
    const navigate = useNavigate();
    let _defectFactory = new DefectFactory();
    let _creationFactory = new CreationFactory();

    const ASSOCIATED_PART = "Associated_Part";
    const DEFECT_TYPE = "Defect_Type";
    const DEFECT_STAGE = "DEFECT_STAGE";
    const STAGE_LIST_FOR_DEFECT_FILTER = ['ENDURANCE CHECK', 'PERFORMANCE CHECK'];

    const [defectDescription, setDefectDescription] = useState(null);
    const [defectTypeOption, setDefectTypeOption] = useState(null);
    const [defectTypeFilteredOption, setDefectTypeFilteredOption] = useState(null);
    const [partTypeOption, setPartTypeOption] = useState(null);
    const [stageOption, setStageOption] = useState(null);
    const [partType, setPartType] = useState(null);
    const [defectType, setDefectType] = useState(null);
    const [defectStage, setDefectStage] = useState(null);
    const [dataFlag, setDataFlag] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {

        let selectedMachineId = props.machineData.product_id;
        _defectFactory.getDefectType(selectedMachineId)
            .then((data) => {
                if (data) {
                    setDefectTypeOption(data);
                    setDefectTypeFilteredOption(data);
                }
            }).catch((err) => {
                // handle error
            })

        let reqData = {
            machineId: props.machineData.id
        }

        _creationFactory.getComponents(reqData)
            .then((res) => {
                if (res) setPartTypeOption(res);
            })
            .catch((err) => {
                console.log(err);
            })

        _creationFactory.getStages(reqData)
            .then((data) => {
                if (data) setStageOption(data);
            })
            .catch((err) => {
                console.log(err);
            })

    }, []);

    useEffect(() => {
        handleDataCompletion();
    }, [partType, defectType, defectDescription, defectStage]);

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    }

    const handleSave = () => {

        if (dataFlag) {
            let reqData = {
                machineId: props.machineData.id,
                defectDescription: defectDescription,
                subStageDetailId: (partType && partType.id) || null,
                defectTypeId: defectType.id,
                stageId: defectStage && defectStage.id
            }

            _defectFactory.createDefect(reqData)
                .then((res) => {
                    redirect(navigate, PAGE_CONST.DEFECT_PAGE);
                })
                .catch((err) => {
                    if (err) setErrorMsg(err);
                })
        }
    }

    const handleInputChange = (event) => {
        setDataFlag(false);
        setErrorMsg(null);
        let value = event && event.target && event.target.value;

        if (value) {
            setDefectDescription(value);
        }
    }

    const handleSelectChange = (inputType, item) => {
        setDataFlag(false);
        setErrorMsg(null);
        switch (inputType) {
            case ASSOCIATED_PART:
                setPartType(item);
                setDefectType(null);
                filterDefectTypeOptions(defectStage, item);
                break;
            case DEFECT_TYPE:
                setDefectType(item);
                break;
            case DEFECT_STAGE:
                setDefectStage(item);
                setDefectType(null);
                filterDefectTypeOptions(item, partType);
                break;
        }
    }

    const filterDefectTypeOptions = (stage, associatedPart) => {

        setDefectTypeFilteredOption(defectTypeOption);
        if (associatedPart) {
            let options = defectTypeOption.filter((option) => {

                let associatedPartList = option.component_types.map((x) => x.toUpperCase());

                if (associatedPartList && associatedPartList.length > 0 && associatedPartList.includes(associatedPart.component_type_name.toUpperCase())) {
                    return true;
                } else {
                    return false;
                }
            });

            if (options.length > 0) {
                setDefectTypeFilteredOption(options);
            }
        } else if (stage) {
            let options = defectTypeOption.filter((option) => {

                let stageList = option.stages.map((x) => x.toUpperCase());
                if (stageList && stageList.length > 0 && stageList.includes(stage.name.toUpperCase())) {
                    return true;
                } else {
                    return false;
                }
            });

            if (options.length > 0) {
                setDefectTypeFilteredOption(options);
            }
        }
    }

    const handleDataCompletion = () => {
        let flag = false;
        setDataFlag(false);
        if (defectDescription && defectType && defectStage) {
            flag = true;
            setDataFlag(true);
        }

        return flag;
    }

    return (
        <>
            <div className="machine-defect">
                <SubHeader
                    handleBackClick={handleBackClick}
                    machineName={'Create Defect'}
                    showDefects={false}
                    showTimeline={false}
                    showBackButton={true}
                />
                <div className="machine-defect-body">
                    <div className="machine-defect-body-header">
                        <div className="defect-body-heading-title">
                            {props.machineData.name}
                        </div>
                    </div>
                    <div className="machine-defect-body-body">
                        <div className="defect-input-container">
                            <div className="input-description">Defect Details</div>
                            <textarea className="defect-details-input-box" id="create-defect-text" placeholder="Defect Description"
                                onChange={(event) => handleInputChange(event)}
                                onFocus={() => handleOnFocusById("create-defect-text")}
                            />
                        </div>
                        <div className="select-container">
                            <CustomSelect
                                id="defect-stage-select"
                                handleChange={(item) => handleSelectChange(DEFECT_STAGE, item)}
                                options={stageOption}
                                placeholder='Defect Stage'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        <div className="select-container">
                            <CustomSelect
                                id="associated-part-select"
                                handleChange={(item) => handleSelectChange(ASSOCIATED_PART, item)}
                                label='component_type_label'
                                options={partTypeOption}
                                placeholder='Associated Part (Optional)'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                            {partType && partType.component_name && <div className="selected-part"> {`Part ID: ${partType.component_name}`}</div>}
                        </div>
                        <div className="select-container">
                            <CustomSelect
                                id="defect-type-select"
                                handleChange={(item) => handleSelectChange(DEFECT_TYPE, item)}
                                options={defectTypeFilteredOption}
                                value={defectType}
                                placeholder='Defect Type'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                    </div>
                    {errorMsg && <div className='error-msg'>
                        {errorMsg}
                    </div>}
                    <div className="machine-defect-body-save-button-container">
                        <button className={dataFlag ? "defect-save-button" : "substage-save-button button-disabled"}
                            onClick={handleSave} >Submit</button>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {

    return {
        machineData: state.machineDataReducer.machineData
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         
//     }
// };

export default connect(mapStateToProps, null)(CreateDefect);