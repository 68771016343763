import "./CustomTable.css"  

import share_excel from "../../common/icons/share_excel.svg";
import download_excel from "../../common/icons/download_excel.svg";
import more_actions_vertical from "../../common/icons/more_actions_vertical.svg";
import excel_icon from "../../common/icons/excel_icon.svg"


export function CustomTable(props) {
  return (

      <table className="custom-table-class">
        <div className="column-data-class">
          <tr>
            {props.columnData.map((val)=>{
            return (
                <th className={val.className} key={val.key} id={val.dataIndex}>{val.title}</th>
              )
            })}
            </tr>
        </div>
        <div className="row-data-class">
        {
          props.rowData.map((val)=>{
          return (
            <tr key={val.key} className="row">
              <td className="name">
                <div className="column-data">
                  <img src={excel_icon} className="excel-icon-class" />
                  <span className="column-1-content">{val.document_name}</span>
                </div>
              </td>
              <td className="size">
                <div className="column-data">{val.document_size}
                </div>
                </td>
              <td className="date">
                <div className="column-data">
                  {val.date_uploaded}
                  </div></td>
              <td className="actions">{
               <div className="table-icons-class column-data">
                <img src={download_excel} className="download-icon"/>
                <img src={share_excel} className="share-icon"/>
                <img src={more_actions_vertical}/>
               </div> 
              }
              </td>
            </tr>
          )
        })
        }
        </div>
      </table>

  );
}
