import {
    createSuperComponentAPI,
    getAllSubAssemblyTypeAPI,
    getSuperComponentProgressDetailsAPI,
    getSuperComponentsAPI,
    getSuperComponentStageDetailsAPI,
    getSuperComponentStagesAPI,
    getSuperComponentsTimeLineAPI,
    getSuperComponentSubStageDetailsAPI,
    postSuperComponentSubStageDetailsAPI,
    updateSuperComponentStageStatusAPI,
    updateSuperComponentStatusAPI,
} from "../../adapter/machineCreationAdapter";

export class SuperComponentCreationFactory {

    constructor() {
        // add something
    }

    getAllType = () => {
 
        let promise = new Promise(function(resolve, reject) {

            console.log("sub Assembly");
            getAllSubAssemblyTypeAPI().then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    reject("error occured");
                }
            });
        })
    
        return promise;
    }

    create = (data) => {
    
        let requestData = {
            sub_assembly_type_id: data.selectedMachineType,
            super_component_name: data.machineName
        }
    
        var promise = new Promise(function(resolve, reject) {

            createSuperComponentAPI(requestData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {

                    if (res.data.data.sub_assembly_type_id) {
                        res.data.data['product_id'] = res.data.data.sub_assembly_type_id;
                    }
                    resolve(res.data.data);
                } else {
                    if (res.data.error && res.data.error.message) {
                        res.data.error.code = "MACHINE_ALREADY_EXIST";
                        reject(res.data.error);
                    } else {
                        reject("error occured");
                    }
                }
            });
        })

        return promise;
    }

    getProgressDetails = (machineId) => {

        let requestData = {
            super_component_name: machineId
        }
    
        var promise = new Promise(function(resolve, reject) {

            getSuperComponentProgressDetailsAPI(requestData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {

                    if (res.data.data.sub_assembly_type_id) {
                        res.data.data['product_id'] = res.data.data.sub_assembly_type_id;
                    }

                    resolve(res.data.data);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    getSubStageDetails = (data) => {
    
        let requestData = {
            super_component_id: data.machineId,
            stage_id: data.stageId,
            sub_stage_id: data.subStageId
        }

        var promise = new Promise(function(resolve, reject) {
            getSuperComponentSubStageDetailsAPI(requestData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
    
                    let response = res.data.data;
                    response.sub_stage_name = res.data.data.machine_sub_stage_name;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            }).catch((err) => {
                console.log("error occured in stage details Api :: " + err);
            });
        })
    
        return promise;
    }
    
    postSubStageDetails = (data) => {
    
        var promise = new Promise(function(resolve, reject) {
            postSuperComponentSubStageDetailsAPI(data).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                    else reject("error occured");
                }
            }).catch((err) => {
                console.log("error occured in stage details Api :: " + err);
            });
        })
    
        return promise;
    }
    
    getStageDetails = (data) => {
        let reqData = {
            super_component_id: data.machineId,
            stage_id: data.stageId
        }
        var promise = new Promise(function(resolve, reject) {
            getSuperComponentStageDetailsAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
    
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            }).catch((err) => {
                console.log("error occured in stage details Api :: " + err);
            });
            
        })

        return promise;
    }
    
    updateStageStatus = (data) => {
    
        let reqData = {
            status: data.status,
            stage_id: data.stageId
        }
    
        var promise = new Promise(function(resolve, reject) {
            updateSuperComponentStageStatusAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    updateStatus = (data) => {
    
        let reqData = {
            super_component_id: data.machineId,
            status: data.status
        }
    
        var promise = new Promise(function(resolve, reject) {
            updateSuperComponentStatusAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    getComponents = (data) => {
    
        let reqData = {
            super_component_id: data.machineId,
        }
    
        var promise = new Promise(function(resolve, reject) {
            getSuperComponentsAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("some error occured")
                }
            });
        })
    
        return promise;
    }
    
    getStages = (data) => {
    
        let reqData = {
            super_component_id: data.machineId,
        }
    
        var promise = new Promise(function(resolve, reject) {
            getSuperComponentStagesAPI(reqData).then((res) => {
                
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("error occured")
                }
            });
        })
    
        return promise;
    }
    
    getTimeLine = (data) => {
    
        let reqData = {
            super_component_id: data.machineId,
        }
    
        var promise = new Promise(function(resolve, reject) {
            getSuperComponentsTimeLineAPI(reqData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    let response = res.data.data;
                    resolve(response);
                } else {
                    if (res && res.data && res.data.error) reject(res.data.error.message);
                    else reject("error occured")
                }
            });
        })
    
        return promise;
    }
}