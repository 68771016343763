import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate} from "react-router-dom";
import { PAGE_CONST } from '../../common/constant/CommonConstants';

import plus_svg from '../../common/icons/plus.svg';
import { redirect } from '../../common/utils/RedirectionUtils';
import DefectDetails from '../../component/DefectDetails/DefectDetails';
import SubHeader from '../../component/SubHeader/SubHeader';
import DefectFactory from '../../helper/DefectFactory';

import "./Defect.css";

function Defect(props) {
    const navigate = useNavigate();
    let _defectFactory = new DefectFactory();

    const [machineName, setMachineName] = useState('');
    const [activeDefectType, setActiveDefectType] = useState("IN_PROGRESS");
    const [defectData, setDefectData] = useState([]);
    const [inProgressCount, setInProgressCount] = useState(0);
    const [resolvedCount, setResolvedCount] = useState(0);
    const IN_PROGRESS = "IN_PROGRESS";
    const RESOLVED = "RESOLVED";

    useEffect(() => {

        let reqData = {
            machineId: props.machineData.id
        }

        _defectFactory.getAllDefects(reqData)
        .then((res) => {

            if (res.machine_name) setMachineName(res.machine_name);
            if (res.defects) {
                setDefectData(res.defects);
                let inProCount = res && res.defects.filter((data) => data.status === "PENDING").length;
                let inResoCount = res && res.defects.filter((data) => data.status === "RESOLVED").length;

                setInProgressCount(inProCount);
                setResolvedCount(inResoCount);
            }
        })
        .catch((err) => {
            console.log("error occured in getAllDefect :: " + JSON.stringify(err));
        })
    }, []);

    useEffect(() => {

        // TODO: update the component state
    }, [activeDefectType])

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    }

    const setActiveState = (state) => {
        setActiveDefectType(state);
    }

    const handleResolveClick = (defectId) => {
        redirect(navigate, PAGE_CONST.RESOLVE_DEFECT_PAGE, {defectId: defectId});
        // navigate(`/machine-progress/${props.machineData.id}/resolveDefect/${defectId}`);
    }

    const getDefectDetails = () => {
        let defectArr;
        let isResolved = false;
        if (activeDefectType === IN_PROGRESS) {
            defectArr = defectData.filter((data) => data.status === "PENDING")
        } else if (activeDefectType === RESOLVED) {
            isResolved = true;
            defectArr = defectData.filter((data) => data.status === "RESOLVED") 
        }

        return defectArr && defectArr.map((data) => {
            return <DefectDetails key={data.id} isResolved={isResolved} resolveButton={!isResolved} data={data} handleResolveClick={(id) => handleResolveClick(id)}/>
        })
    }

    const handleAddDefectClick = () => {
        redirect(navigate, PAGE_CONST.CREATE_DEFECT_PAGE);
        // navigate(`/machine-progress/${props.machineId}/${machineName}/createDefect`);
    }

    return (
        <>
            <div className="defect">
                <SubHeader 
                    handleBackClick={handleBackClick}
                    machineName={'Defects'}
                    showDefects={false}
                    showTimeline={false}
                    showBackButton={true}
                />
                {/* <div className="defect-header">
                    <div className="defect-heading">
                        <img src={back_svg} alt="Back Button" onClick={handleBackClick}/>
                        <span className="defect-name">Defects</span>
                    </div>
                </div> */}
                <div className="defect-body">
                    <div className="defect-body-header">
                        <div className="defect-heading-title">
                            {machineName}
                        </div>
                    </div>
                    <div className="defect-type">
                        <div className={
                            activeDefectType === IN_PROGRESS 
                                ? "in-progress-defect defect-tag active-defect-type" : "in-progress-defect defect-tag"} 
                            onClick={() => setActiveState(IN_PROGRESS)}>
                            {`Inprogress (${inProgressCount})`}
                        </div>
                        <div className={
                            activeDefectType === RESOLVED 
                                ? "resolved-defect defect-tag active-defect-type" : "resolved-defect defect-tag"} onClick={() => setActiveState(RESOLVED)}>
                            {`Resolved (${resolvedCount})`}
                        </div>
                    </div>
                    {getDefectDetails()}
                </div>
                <div className="add-defect-icon-container">
                    <img src={plus_svg} alt="Back Button" onClick={() => handleAddDefectClick()}/>
                </div>
            </div>
        </>
  );
}

const mapStateToProps = state => {

    return {
      machineData: state.machineDataReducer.machineData
    };
};

export default connect(mapStateToProps, null)(Defect);