import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QRScanner from "../../component/QrScanner/QrScanner";
import "./AssemblySubStage.css";
import SubHeader from "../../component/SubHeader/SubHeader";
import {
    handleOnFocusById,
    handleScrollToTop,
} from "../../common/utils/CommonUtils";
import { redirect } from "../../common/utils/RedirectionUtils";
import { PAGE_CONST } from "../../common/constant/CommonConstants";
import { connect } from "react-redux";
import { setActiveSubStageAction } from "../../modules/actions/action";
import CreationFactory from "../../helper/CreationFactory";
import CustomSelect from "../../component/Select/Select";
import { SUB_STAGE_CONST } from "../../common/constant/CommonConstants";

function AssemblySubStage(props) {
    const params = useParams();
    const navigate = useNavigate();
    let _creationFactory = new CreationFactory();

    const [machineId, setMachineId] = useState("");
    const [machineName, setMachineName] = useState("");
    const [stageId, setStageId] = useState("");
    const [subStageId, setSubStageId] = useState("");
    const [dataFlag, setDataFlag] = useState(false);
    const [isPopupOpen, setIsPopUpOpen] = useState(false);
    const [subStageData, setSubStageData] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [partNumber, setPartNumber] = useState(null);
    const [subStageType, setSubStageType] = useState(null);
    const [submitButtonText, setSubmitButtonText] = useState(
        SUB_STAGE_CONST.SUBMIT
    );

    useEffect(() => {
        setMachineId(props.machineData.id);
        setStageId(props.activeData.stage.id);
        setSubStageId(props.activeData.subStage.id);

        let data = {
            machineId: props.machineData.id,
            stageId: props.activeData.stage.id,
            subStageId: props.activeData.subStage.id,
        };

        _creationFactory.getSubStageDetails(data)
            .then((res) => {
                if (res.machine_name) setMachineName(res.machine_name);
                saveSubStageData(res);
                let len = res.sub_stage_data.length;
                setPartNumber(len);
            });
    }, []);

    useEffect(() => { }, [subStageData, submitButtonText, props])

    const saveSubStageData = (subStageData) => {
        setSubStageData(subStageData);
        setSubStageType(subStageData.type === SUB_STAGE_CONST.ASSEMBLY ?
            SUB_STAGE_CONST.ASSEMBLY_SUBSTAGE_COMPLETE : SUB_STAGE_CONST.TEST_SUBSTAGE_COMPLETE);
    }

    const handleScan = (result, componentId, valueType) => {
        setErrorMsg(null);
        setDataFlag(false);
        setSubmitButtonText(SUB_STAGE_CONST.SUBMIT);

        if (valueType) {
            var checkboxes = document.getElementsByClassName(componentId);
            Array.from(checkboxes).forEach((item) => {
                if (item.name !== valueType) item.checked = false;
                else {
                    item.checked = true;
                }
            });
        }

        let ssd = subStageData;
        ssd.sub_stage_data.map((component) => {
            if (component.id === componentId) {
                if (SUB_STAGE_CONST.COMPONENT_ARR.includes(component.type)) {
                    component.scanned_id = result;
                    component.isScanned = true;
                } else if (component.type === SUB_STAGE_CONST.TEST) {
                    // checkbox
                    if (component.test_input_type === SUB_STAGE_CONST.YES_NO) {
                        if (component.isScanned === true) {
                            component.isScanned = false;
                            component.scanned_id = null;
                        } else {
                            component.isScanned = true;
                            component.scanned_id = SUB_STAGE_CONST.YES;
                        }
                    } else if (component.test_input_type === SUB_STAGE_CONST.OK_NOK) {
                        component.isScanned = true;
                        component.scanned_id = valueType;
                    } else if (
                        component.test_input_type === SUB_STAGE_CONST.START_DATE_END_DATE
                    ) {
                        component.isScanned = true;
                        component.scanned_id = result;
                    }
                } else if (component.type === SUB_STAGE_CONST.INPUT_FIELD) {
                    component.isScanned = true;
                    component.scanned_id = result;
                }
            }
        });

        const unScannedComponent = ssd.sub_stage_data.filter((element) => {
            if (element.is_required) {
                return !(element.isScanned && element.scanned_id);
            } else {
                return false;
            }
        }).length;
        if (unScannedComponent === 0) {
            setDataFlag(true);
        }

        const notOkComponent = ssd.sub_stage_data.filter((element) =>
            element.scanned_id && element.scanned_id === SUB_STAGE_CONST.NOK
                ? true
                : false
        ).length;
        if (notOkComponent > 0) {
            setSubmitButtonText(SUB_STAGE_CONST.REPORT_DEFECT_TEXT);
        } else {
            setSubmitButtonText(SUB_STAGE_CONST.SUBMIT);
        }

        setSubStageData({ ...ssd });
    };

    const handleSave = () => {
        if (submitButtonText === SUB_STAGE_CONST.REPORT_DEFECT_TEXT) {
            redirect(navigate, PAGE_CONST.CREATE_DEFECT_PAGE);
            // navigate(`/machine-progress/${machineId}/${machineName}/createDefect`);
            return;
        }

        if (dataFlag) {
            if (!isPopupOpen) {
                setIsPopUpOpen(true);
                handleScrollToTop();
                return;
            }

            let reqData = subStageData.sub_stage_data.map((component) => {
                let data = {
                    sub_stage_detail_id: component.id,
                    type: component.type,
                    component_name: null,
                    test_response: null,
                    input_field_response: null,
                };

                if (SUB_STAGE_CONST.COMPONENT_ARR.includes(component.type)) {
                    data.component_name = component.scanned_id;
                } else if (component.type === SUB_STAGE_CONST.TEST) {
                    data.test_response = component.scanned_id;
                } else if (component.type === SUB_STAGE_CONST.INPUT_FIELD) {
                    data.input_field_response = component.scanned_id;
                }

                return data;
            });

            let data = {
                stage_id: stageId,
                sub_stage_id: subStageId,
                sub_stage_data: reqData,
            };

            _creationFactory
                .postSubStageDetails(data)
                .then((res) => {
                    let data = {
                        subStageType: subStageType,
                        partNumber: partNumber,
                    };
                    redirect(navigate, PAGE_CONST.SUB_STAGE_COMPLETE_PAGE, data);
                    // navigate(`/machine-progress/complete/${subStageType}/${machineId}/${subStageData.machine_name}/${subStageData.sub_stage_name}/${partNumber}`);
                })
                .catch((err) => {
                    setErrorMsg(err);
                    setIsPopUpOpen(false);
                });
        }
    };

    const handlePopUpCTA = (type) => {
        if (type === "cancel") {
            setIsPopUpOpen(false);
        } else {
            handleSave();
        }
    };

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    };

    const handleMachineTimeLineClick = () => {
        redirect(navigate, PAGE_CONST.ASSEMBLY_TIMELINE_PAGE);
    };

    const handleDefectClick = () => {
        redirect(navigate, PAGE_CONST.DEFECT_PAGE);
        // navigate(`/machine-progress/${machineId}/defects`);
    };

    const getSubStageDescription = (type) => {
        let value = "";

        if (subStageData && subStageData.meta_data) {
            subStageData.meta_data.forEach((meta) => {
                if (meta.key === SUB_STAGE_CONST.DESCRIPTION) value = meta.value;
            });
        }

        return value;
    };

    const getSubStageConfiguration = () => {
        let html = "";

        if (subStageData && subStageData.meta_data) {
            html = subStageData.meta_data.map((meta) => {
                let detail = "";
                if (meta.type === SUB_STAGE_CONST.BOX) {
                    detail = (
                        <div className="conf-item" key={meta.key}>
                            <span className="conf-value">{meta.value}</span>
                            <span className="conf-name">{meta.key}</span>
                        </div>
                    );
                }

                return detail;
            });
        }

        return html;
    };

    const getInputHtml = () => {
        let substageInputArray = subStageData && subStageData.sub_stage_data;

        let html = substageInputArray.map((component) => {
            let divDetails;
            if (SUB_STAGE_CONST.COMPONENT_ARR.includes(component.type)) {
                divDetails = (
                    <div key={component.id} className="mrb-item input-container">
                        <QRScanner
                            placeholder={component.component_label}
                            handleScan={(result) => handleScan(result, component.id)}
                        />
                    </div>
                );
            } else if (component.type === SUB_STAGE_CONST.TEST) {
                if (component.test_input_type === SUB_STAGE_CONST.YES_NO) {
                    divDetails = (
                        <div key={component.id} className="test-checkbox-data">
                            <div className="question-desc">{component.test_label}</div>
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    id={component.id}
                                    className="test-checkbox"
                                    onClick={() => handleScan(null, component.id)}
                                />
                            </div>
                        </div>
                    );
                } else if (component.test_input_type === SUB_STAGE_CONST.OK_NOK) {
                    divDetails = (
                        <div
                            key={component.id}
                            className={
                                component.scanned_id &&
                                    component.scanned_id === SUB_STAGE_CONST.NOK
                                    ? "test-double-checkbox-data not-ok"
                                    : "test-double-checkbox-data"
                            }
                        >
                            <div className="double-checkbox-question-desc">
                                {component.test_label}
                            </div>
                            <div className="double-checkbox-container">
                                <input
                                    type="checkbox"
                                    name="OK"
                                    className={`test-checkbox ${component.id}`}
                                    onClick={() =>
                                        handleScan(null, component.id, SUB_STAGE_CONST.OK)
                                    }
                                />
                                <span className="checkbox-text">OK</span>
                            </div>
                            <div className="double-checkbox-container">
                                <input
                                    type="checkbox"
                                    name="NOK"
                                    className={`test-checkbox ${component.id}`}
                                    onClick={() =>
                                        handleScan(null, component.id, SUB_STAGE_CONST.NOK)
                                    }
                                />
                                <span className="checkbox-text">NOK</span>
                            </div>
                        </div>
                    );
                } else if (
                    component.test_input_type === SUB_STAGE_CONST.START_DATE_END_DATE
                ) {
                    divDetails = (
                        <div key={component.id} className="calendar-container">
                            <DatePicker
                                wrapperClassName="datePicker-custom"
                                selected={component.scanned_id || new Date()}
                                onChange={(date) => handleScan(date, component.id)}
                                // selected={startDate}
                                // onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={15}
                                dateFormat="Pp"
                            // placeholderText={component.test_label}
                            />
                        </div>
                    );
                }
            } else if (component.type === SUB_STAGE_CONST.INPUT_FIELD) {
                if (component.input_field_type === SUB_STAGE_CONST.CHOICE) {
                    divDetails = (
                        <div className="">
                            <CustomSelect
                                id={component.id}
                                handleChange={(item) => {
                                    handleScan(item.name, component.id);
                                }}
                                isClearable={true}
                                options={component.input_field_values}
                                placeholder="Select Field Value"
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    );
                } else {
                    divDetails = (
                        <div key={component.id} className="test-input-container">
                            <input
                                className="test-input-box"
                                id={component.id}
                                type="text"
                                name="machine-id"
                                autoComplete="off"
                                placeholder={component.input_field_label}
                                // value={component.scanned_id}
                                onChange={(event) => {
                                    component.isScanned = true;
                                    component.scanned_id = event.target.value;
                                    handleScan(event.target.value, component.id);
                                }}
                                onFocus={() => handleOnFocusById(component.id)}
                            />
                        </div>
                    );
                }
            }
            return divDetails;
        });

        return html;
    };

    const getPopUPHtml = () => {
        let html = "";

        if (dataFlag) {
            if (subStageType === "testSubStageComplete") {
                html = (
                    <div className="component assembly-popup-item">
                        {`I hereby confirm that all the tests in ${props.activeData &&
                            props.activeData.subStage &&
                            props.activeData.subStage.name
                            } have been performed and all parts are working.`}
                    </div>
                );
            } else {
                html = subStageData.sub_stage_data.map((component) => {
                    return (
                        <>
                            <div key={component.id} className="component assembly-popup-item">
                                {`${component.component_label}: ${component.scanned_id}`}
                            </div>
                        </>
                    );
                });
            }
        }
        return html;
    };

    return (
        <>
            {subStageData && (
                <div
                    className={
                        isPopupOpen ? "assembly-sub-stage blur" : "assembly-sub-stage"
                    }
                >
                    <SubHeader
                        handleDefectClick={() => handleDefectClick()}
                        handleMachineTimeLineClick={() => handleMachineTimeLineClick()}
                        handleBackClick={() => handleBackClick()}
                        machineName={props.machineData.name}
                        showDefects={true}
                        showTimeline={true}
                        showBackButton={true}
                        isBlur={isPopupOpen ? true : false}
                    />
                    <div
                        className={
                            isPopupOpen
                                ? "assembly-sub-stage-body blur"
                                : "assembly-sub-stage-body"
                        }
                    >
                        <div className="assembly-sub-stage-body-header">
                            <div className="body-heading-title">
                                {props.activeData &&
                                    props.activeData.subStage &&
                                    props.activeData.subStage.name}
                            </div>
                            <div className="body-heading-description">
                                {getSubStageDescription()}
                            </div>
                            <div className="body-heading-configuration">
                                {getSubStageConfiguration()}
                            </div>
                        </div>
                        <>
                            {getInputHtml()}
                            {errorMsg && <div className="error-msg">{errorMsg}</div>}
                        </>
                        <div className="assembly-sub-stage-save-button-container">
                            <button
                                className={
                                    SUB_STAGE_CONST.REPORT_DEFECT_TEXT === submitButtonText
                                        ? "substage-save-button error-button"
                                        : dataFlag
                                            ? "substage-save-button"
                                            : "substage-save-button button-disabled"
                                }
                                onClick={() => handleSave()}
                            >
                                {submitButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isPopupOpen && (
                <div className="confirmation-popup" id="confirmation-popup">
                    <div className="confirmation-heading">
                        {subStageType === "testSubStageComplete"
                            ? `${props.activeData &&
                            props.activeData.subStage &&
                            props.activeData.subStage.name
                            } Confirmation`
                            : "Part ID Confirmation"}
                    </div>
                    <div className="confirmation-data">
                        <div className="date assembly-popup-item">
                            Date: {moment(new Date()).format("DD/MM/YYYY hh:mm:ss a")}
                        </div>
                        {getPopUPHtml()}
                    </div>
                    <div className="confirmation-cta">
                        <div
                            className="cancel-cta assembly-popup-cta "
                            onClick={() => handlePopUpCTA("cancel")}
                        >
                            Cancel
                        </div>
                        <div
                            className="confirm-cta assembly-popup-cta"
                            onClick={() => handlePopUpCTA("confirm")}
                        >
                            Confirm
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        machineData: state.machineDataReducer.machineData,
        activeData: state.machineActiveDataReducer.activeData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveSubStageAction: (payload) =>
            dispatch(setActiveSubStageAction(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblySubStage);
