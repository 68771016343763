import "../common/AxiosInterceptor/AxiosInterceptor";
const axios = require('axios');

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllMachineTypeAPI = async () => {

    return axios({
        method: 'get',
        url: `${BASE_URL}api/products`,
        authRequired: true
    })
        .then(function (response) {
            return Promise.resolve(response);
        });
}

export const getAllSubAssemblyTypeAPI = async () => {

    return axios({
        method: 'get',
        url: `${BASE_URL}api/subAssemblyTypes`,
        authRequired: true
    })
        .then(function (response) {
            return Promise.resolve(response);
        });
}

export const createMachineAPI = async (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/createMachine`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export const createSuperComponentAPI = async (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/createSuperComponent`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export const getMachineProgressDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/getMachine`,
        data: {
            ...data
        },
        authRequired: true
    })
        .then(function (response) {
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export const getSuperComponentProgressDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/getSuperComponent`,
        data: {
            ...data
        },
        authRequired: true
    })
        .then(function (response) {
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export const getMachineSubStageDetailsAPI = (data) => {

    // let response =  {
    //     data: {
    //         "status": 200,
    //         "data": {
    //             "meta_data": [],
    //             "sub_stage_data": [
    //                 {
    //                     "id": 695,
    //                     "type": "COMPONENT_ADDITION",
    //                     "component_label": "Tablet ID",
    //                     "component_name": null,
    //                     "test_label": null,
    //                     "test_input_type": null,
    //                     "test_response": null,
    //                     "input_field_label": null,
    //                     "input_field_response": null
    //                 },
    //                 {
    //                     "id": 696,
    //                     "type": "COMPONENT_ADDITION",
    //                     "component_label": "Daiphragm Pump ID",
    //                     "component_name": null,
    //                     "test_label": null,
    //                     "test_input_type": null,
    //                     "test_response": null,
    //                     "input_field_label": null,
    //                     "input_field_response": null
    //                 }
    //             ]
    //         }
    //     }
    // }

    // return Promise.resolve(response);
    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/getSubStageDetails`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSuperComponentSubStageDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/getSubStageDetails`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const postMachineSubStageDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/subStages`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const postSuperComponentSubStageDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/updateSubStages`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getMachineStageDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/getStageDetails`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSuperComponentStageDetailsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/getStageDetails`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const updateMachineStageStatusAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/stages/updateStatus`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const updateSuperComponentStageStatusAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/stages/updateStatus`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const updateMachineStatusAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/updateStatus`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const updateSuperComponentStatusAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/updateStatus`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getProductDefectTypesAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/products/getDefectTypes`,
        authRequired: true,
        data
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSubAssemblyDefectTypesAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/subAssemblies/getDefectTypes`,
        authRequired: true,
        data
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getProductDefectResolutionTypesAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/products/getDefectResolutionTypes`,
        authRequired: true,
        data: data
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSubAssemblyDefectResolutionTypesAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/subAssemblies/getDefectResolutionTypes`,
        authRequired: true,
        data: data
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getMachineComponentsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/getComponents`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSuperComponentsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/getComponents`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getProductAllDefectsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/getAllDefects`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSubAssemblyAllDefectsAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/getAllDefects`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getProductDefectByIdAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/getDefect`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const getSubAssemblyDefectByIdAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/getDefect`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const createProductDefectAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/createDefect`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const createSubAssemblyDefectAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/createDefect`,
        data: data,
        authRequired: true
    })
        .then(function (response) {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const resolveProductDefectAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/resolveDefect`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const resolveSubAssemblyDefectAPI = (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/resolveDefect`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const getMachineStagesAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/getStages`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const getSuperComponentStagesAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/getStages`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const getMachineTimeLineAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/machines/timeline`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const getSuperComponentsTimeLineAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/supercomponents/timeline`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const sendOtpAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/sendOTP`,
        data: data
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const verifyOtpAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/verifyOTP`,
        data: data
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const logoutAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/logout`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}

export const orderDetailsAPI = (data) => {
    return axios({
        method: 'get',
        url: `${BASE_URL}api/orders`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}
export const orderMachineDetailsAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/orders/getMachines`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}
export const machinePartDetailAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/orders/machines/getMachine`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}
export const excelFileUploadAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/upload`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}
export const searchHeaderAPI = (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}api/search`,
        data: data,
        authRequired: true
    }).then(function (response) {
        return response;
    }).catch((err) => {
        return err;
    });
}


