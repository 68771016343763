import { CREATION_CONSTANTS } from "../../common/constant/FactoryConstants";
import { MachineDefectFactory } from "../factory/MachineDefectFactory";
import { SuperComponentDefectFactory } from "../factory/SuperComponentDefectFactory";

class DefectFactoryMapper {
    constructor () {
        const superComponentDefectFactory = new SuperComponentDefectFactory();
        const machineDefectFactory = new MachineDefectFactory();

        this.factories = {};
        this.factories[CREATION_CONSTANTS.SUPER_COMPONENT] = superComponentDefectFactory;
        this.factories[CREATION_CONSTANTS.MACHINE] = machineDefectFactory;
    }

    factory = type => type && this.factories[type];
}

export default DefectFactoryMapper;