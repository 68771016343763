import SubStageComplete from "../../component/AssemblyComplete/SubStageComplete";
import AssemblyConfirmationScreen from "../../component/AssemblyConfirmationScreen/AssemblyConfirmationScreen";
import AssemblyTimeLine from "../../component/AssemblyTimeLine/AssemblyTimeLine";
import MachineConsentScreen from "../../component/MachineConsentScreen/MachineConsentScreen";
import AssemblySubStage from "../../container/AssemblySubStage/AssemblySubStage";
import CreateDefect from "../../container/CreateDefect/CreateDefect";
import Defect from "../../container/Defect/Defect";
import HomePage from "../../container/HomePage/HomePage";
import LoginPage from "../../container/LoginPage/LoginPage";
import OrderDetailsPage from "../../container/OrderDetails/OrderDetailsPage";
import MachineProgress from "../../container/MachineProgress/MachineProgress";
import MachineRegistrationPage from "../../container/MachineRegistrationPage/MachineRegistrationPage";
import POListing from "../../container/POListing/POListing";
import ResolveDefect from "../../container/ResolveDefect/ResolveDefect";
import MachineDetailsPage from "../../container/MachineDetails/MachineDetailsPage";

export const routes = [{
    key: "login",
    path: '/login',
    exact: true,
    authNeeded: false,
    element: <LoginPage />
}, {
    key: 'homepage',
    path: '/home-page',
    exact: true,
    authNeeded: true,
    element: <HomePage />
}, {
    key: 'machine-creation',
    path: '/machine-creation',
    exact: true,
    authNeeded: true,
    element: <MachineRegistrationPage />
}, {
    key: 'machine-progress',
    path: '/machine-progress',
    exact: true,
    authNeeded: true,
    element: <MachineProgress />
}, {
    key: 'sub-stage',
    path: '/machine-progress/assembly/sub-stage',
    exact: true,
    authNeeded: true,
    element: <AssemblySubStage />
}, {
    key: 'assembly-complete',
    path: '/machine-progress/complete/:type/:partNumber',
    exact: true,
    authNeeded: true,
    element: <SubStageComplete />
}, {
    key: 'confirmation',
    path: '/machine-progress/confirmation',
    exact: true,
    authNeeded: true,
    element: <AssemblyConfirmationScreen />
}, {
    key: "timeline",
    path: '/machine-progress/assembly/timeline',
    exact: true,
    authNeeded: true,
    element: <AssemblyTimeLine />
}, {
    key: "consent",
    path: '/machine-progress/assembly/consent',
    exact: true,
    authNeeded: true,
    element: <MachineConsentScreen />
}, {
    key: "defects",
    path: '/machine-progress/defects',
    exact: true,
    authNeeded: true,
    element: <Defect />
}, {
    key: "create-defect",
    path: '/machine-progress/create-defect',
    exact: true,
    authNeeded: true,
    element: <CreateDefect />
}, {
    key: "resolve-defect",
    path: '/machine-progress/resolve-defect/:defectId',
    exact: true,
    authNeeded: true,
    element: <ResolveDefect />
}, {
    key: "production-order-listing",
    path: '/production-order-listing',
    exact: true,
    authNeeded: true,
    element: <POListing />
}, {
    key: "production-order-detail",
    path: '/production-order-detail/:orderId',
    exact: true,
    authNeeded: true,
    element: <OrderDetailsPage />
}, {
    key: "machine-detail",
    path: '/machine-detail/:orderId/:machineId',
    exact: true,
    authNeeded: true,
    element: <MachineDetailsPage />
}]
