// {behavior: "smooth", block: "center", inline: "nearest"}
export const handleOnFocusById = (id) => {

    try {
        document.getElementById(id).scrollIntoView({block: "center", inline: "nearest"});
    } catch (error) {
        console.log("error occured in handleOnFocus!! ");
    }
}

export const handleScrollToTop = () => {
    window.scrollTo(0, 0);
}

export const blurApp = () => {

    try {
        let elementList = document.getElementsByTagName("button");

        Array.from(elementList).forEach(function (element) {
            element.classList.add("pointer-event-none");
        });
        document.getElementById('App').classList.add('blur-with-pointer');
    } catch (err) {
        console.log("error occured in blurApp");
    }
}

export const unBlurApp = () => {

    try {
        let elementList = document.getElementsByTagName("button");

        Array.from(elementList).forEach(function (element) {
            element.classList.remove("pointer-event-none");
        });
        document.getElementById('App').classList.remove('blur-with-pointer');
    } catch (err) {
        console.log("error occured in unBlurApp");
    }
}