import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./MachineConsentScreen.css";
import SubHeader from '../SubHeader/SubHeader';
import moment from 'moment';
import { PAGE_CONST, TIMELINE_CONST } from '../../common/constant/CommonConstants';
import { redirect } from '../../common/utils/RedirectionUtils';
import { connect } from 'react-redux';
import CreationFactory from '../../helper/CreationFactory';

function MachineConsentScreen(props) {

    const params = useParams();
    const navigate = useNavigate();

    const [machineId, setMachineId] = useState(null);
    const [machineName, setMachineName] = useState(null);
    const [isCheckBoxClicked, setIsCheckBocClicked] = useState(false);

    let _creationFactory = new CreationFactory();

    useEffect(() => {
        let mId = props.machineData.id;
        let mName = props.machineData.name;

        setMachineId(mId);
        setMachineName(mName);
    }, []);

    const handleConsent = () => {

        if (isCheckBoxClicked) {
            let data = {
                machineId: machineId,
                status: 'COMPLETED'
            }

            _creationFactory.updateStatus(data)
            .then(() => {
                redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
            })
            .catch((error) => {
                console.log('error occured in updating machine status');
            })
        }
    }

    const handleCheckBoxClick = () => {

        setIsCheckBocClicked(!isCheckBoxClicked);
    }

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    }

    return (
        <>
            <div className="machine-consent-screen">
                <SubHeader 
                    handleBackClick={() => handleBackClick()}
                    machineName={machineName}
                    showDefects={false}
                    showTimeline={false}
                    showBackButton={true}
                />
                <div className="machine-consent-screen-body">
                    <div className="machine-consent-screen-text-container">
                    {`I hereby declare that I have tested the device 
                    for its performance and endurance on ${moment(new Date).format(TIMELINE_CONST.DATE_TIME_FORMAT)}. There were no defects identified 
                    with the device and if there were, they were resolved and the device 
                    was again tested for performance and endurance before processing to next stage.`}
                    </div>
                    <div className="machine-consent-screen-save-button-container">
                        <div className="consent-checkbox-container">
                            <input type="checkbox" className="consent-checkbox" value="value" checked={isCheckBoxClicked} onChange={() => handleCheckBoxClick()}/>
                            <span className="consent-checkbox-text">I hereby confirm that the issue with part 1 has been resolved. </span>
                        </div>
                        <button className={isCheckBoxClicked ? "consent-home-button" : "consent-home-button button-disabled" }
                            onClick={handleConsent}>Mark Machine Complete</button>
                    </div>  
                </div>
            </div>
        </>
  );
}

const mapStateToProps = state => {

    return {
        machineData: state.machineDataReducer.machineData
    };
};

export default connect(mapStateToProps, null)(MachineConsentScreen);