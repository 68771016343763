// import React, { Component, useState } from 'react'
// import QrReader from 'react-qr-reader';
import React, {useEffect, useState} from "react";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
// import BarcodeReader from 'react-barcode-reader';
// import BarCodeScanner from 'barcode-react-scanner';
// import { BarCodeScanner } from 'expo-barcode-scanner';
// import {Html5QrcodeScanner} from "html5-qrcode";
// import BarcodeScanner from 'react-native-barcode-scanner-google';
// import { QrReader } from '@blackbox-vision/react-qr-reader';
// import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
// import { useBarcode } from '@createnextapp/react-barcode';
import barcode from "barcode-hid-reader";
// import QrReader from 'react-qr-reader'
import Quagga from 'quagga';
import back_svg from '../../common/icons/ep_back.svg';
import success_tick from '../../common/icons/success_tick.svg';
import qr_scanner from '../../common/icons/qr_scanner.svg';

import './QrScanner.css';
import { placeholder } from "@babel/types";
import { handleOnFocusById } from "../../common/utils/CommonUtils";
import Html5QrcodePlugin from "./Html5QrcodePlugin";

export default function QRScanner (props) {

    // const [data, setData] = React.useState("Not Found");
    const [value, setValue] = React.useState('');
    const [isQrScannerOpen, setIsQrScannerOpen] = useState(false);
    // TEMP
    const [data, setData] = useState(null);

    // let barcodeScanner = BarcodeScanner();
    const handleScan = (err, result) => {
        if (result) {
            setValue(result.text);
            props.handleScan(result.text);
            setIsQrScannerOpen(false);
        } else {
            console.log("Not Found !!");
        }
    }

    useEffect(() => {
        // console.log(props);
    }, [props])
    
    const handleInputChange = (event) => {
        const value = event.target.value;

        setValue(value);
        props.handleScan(value);
    }

    const handleQrScannerOpen = () => {

        let tempQRopen = isQrScannerOpen;
        setIsQrScannerOpen(!isQrScannerOpen);
    }

    const onNewScanResult = (decodedText, decodedResult) => {
        // Handle the result here.

        if (decodedText && decodedResult) {
            setValue(decodedText);
            props.handleScan(decodedText);
            setIsQrScannerOpen(false);
        }
    }

    const onNewScanError = (error) => {
        console.log(error)
    }

    return (
        <>
            <div className="qr-scanner">
                {/* <div className={isQrScannerOpen ? "qr-input-container height-19"  : "qr-input-container height-100"}> */}
                <div className="qr-input-container">
                    <input
                        id={`scanner-${props.placeholder}`}
                        className="input-box"
                        autoComplete="off"
                        type="text"
                        name="machine-id"
                        placeholder={props.placeholder || "Scan or Enter Machine Id"}
                        value={props.value || value}
                        onChange={(event) => handleInputChange(event)}
                        onFocus={() => handleOnFocusById(`scanner-${props.placeholder}`)}
                    />
                    {/* <div className={isQrScannerOpen ? "input-icons top-7" : "input-icons top-30"}> */}
                    <div className={"input-icons"}>
                        {value && <img className="scanner-icon" src={success_tick} alt="success tick"/>}
                        <img className="scanner-icon" src={qr_scanner} alt="scanner icon" onClick={() => handleQrScannerOpen()}/>
                    </div>
                </div>
                {isQrScannerOpen && <div className="qr-scanner-body" id="qr-scanner-body-id">
                    <Html5QrcodePlugin
                        fps={100}
                        // qrbox={{'width': 100, 'height': 140}}
                        disableFlip={false}
                        aspectRatio={'1.777778'}
                        qrCodeSuccessCallback={onNewScanResult}
                        qrCodeErrorCallback={onNewScanError}
                        qrcodeRegionId={"qr-scanner-body"}
                        // verbose={true} // for logging
                        />
                </div>}
            </div>
        </>
    );
}


// if (!html5QrcodeScanner) {
        //     html5QrcodeScanner = new Html5QrcodeScanner(
        //         "qr-scanner-body-id",
        //         { fps: 10, qrbox: {width: 250, height: 250} },
        //         /* verbose= */ false);
        // }

        // if (tempQRopen === false && !html5QrcodeScanner) {

        //     html5QrcodeScanner = new Html5QrcodeScanner(
        //         "qr-scanner-body-id", { fps: 10, qrbox: 250 });
        //     html5QrcodeScanner.render((data) => {
        //         console.log(data);

        //         setValue(value);
        //         props.handleScan(value);
        //         html5QrcodeScanner.clear();
        //     }, (err) => {
        //         console.log(err);
        //     });
        // } else if (tempQRopen === false) {
        //     html5QrcodeScanner.clear();
        // }