import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./DragDropFileUpload.css";
import drag_drop_cross from "../../common/icons/drag_drop_cross.svg";
import { excelFileUpload } from "../../helper/ProductionOrderHelper"


const fileTypes = ["xls", "xlsx"];

function DragDropFileUpload(props) {
    const [file, setFile] = useState(null);
    const [inputType, setInputType] = useState("production");
    console.log(inputType)
    const handleChange = (file) => {
        setFile(file);
    };

    const inputTypeHandler = (event) => {
        console.log(event);
        setInputType(event.target.id);
    };

    const handleSubmitClick = (excelFile) => {
        var fileType;
        if (inputType === "component") fileType = "COMPONENTS";
        else fileType = "ORDER";

        excelFileUpload(fileType, excelFile)
            .then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err);
            });
        console.log("submit button was clicked!!!");
    }

    return (
        <div className="upload-container">
            <div className="close-button-class">
                <img
                    className="closing-cross-svg"
                    src={drag_drop_cross}
                    alt="close"
                    onClick={props.close}
                />
            </div>
            <div className="heading-container">
                <h3>File Upload</h3>
            </div>
            <div className="upload-type-container">
                <div className="upload-prod">
                    <input
                        type="radio"
                        id="production"
                        name="upload-type"
                        onChange={(id) => {
                            inputTypeHandler(id);
                        }}
                        defaultChecked={true}
                    />
                    <span>Production Order</span>
                </div>

                <div className="upload-component">
                    <input
                        type="radio"
                        id="component"
                        name="upload-type"
                        onChange={(id) => {
                            inputTypeHandler(id);
                        }}
                    />
                    <span>Component</span>
                </div>
            </div>
            <div className="upload-box">
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                />
                <div className="submit-btn" onClick={() => {
                    handleSubmitClick(file);
                    props.close();
                }}>
                    Submit
                </div>
            </div>
        </div>
    );
}

export default DragDropFileUpload;
