import React, { useState, useEffect } from 'react';
import { useNavigate, withRouter, useParams } from "react-router-dom";
import back_svg from '../../common/icons/ep_back.svg';
import defect_svg  from '../../common/icons/defect.svg';
import machine_progress  from '../../common/icons/machine_progress.svg';
import assembly_complete_svg  from '../../common/icons/assembly-complete.svg';
import "./SubStageComplete.css";
import SubHeader from '../SubHeader/SubHeader';
import { redirect } from '../../common/utils/RedirectionUtils';
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import { connect } from 'react-redux';

function SubStageComplete(props) {

    const params = useParams();
    const navigate = useNavigate();

    const [machineId, setMachineId] = useState(null);
    const [subStageName, setSubStageName] = useState(null);
    const [partNumber, setPartNumber] = useState(null);
    const [machineName, setMachineName] = useState(null);
    const [type, setType] = useState(null);
    
    const handleClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    }

    useEffect(() => {
        let type = params.type;
        let partNumber = params.partNumber;

        let mId = props.machineData.id;
        let subStageName = props.activeData.subStage.name;
        let machineName = props.machineData.name;

        setMachineId(mId);
        setSubStageName(subStageName);
        setPartNumber(partNumber);
        setMachineName(machineName);
        setType(type);
    }, []);

    const getTextHtml = () => {

        let html;
        if (type  === "assemblySubStageComplete") {
            html = `${partNumber} Parts for machine ${machineName} are assembled at ${subStageName}`;
        } else if (type === 'assemblyComplete') {
            html = `Parts for machine ${machineName} are assembled`;
        } else if (type === 'testSubStageComplete') {
            html = `Machine ${machineName} has passed the ${subStageName} Test`;
        }

        return html;
    }

    const handleMachineTimeLineClick = () => {
        redirect(navigate, PAGE_CONST.ASSEMBLY_TIMELINE_PAGE);
    }

    const handleDefectClick = () => {
        navigate(`/machine-progress/${machineId}/defects`);
    }

//     <div className="assembly-comlete-header">
//     <div className="heading">
//         {/* <img src={back_svg} alt="My Happy SVG"/> */}
//         {machineName}
//     </div>
//     <div className="header-icons">
//         <div className="danger">
//             <img src={defect_svg} alt="danger svg" onClick={handleDefectClick}/>
//         </div>
//         <div className="in-progress">
//             <img src={machine_progress} alt="machine progress svg" onClick={handleMachineTimeLineClick}/>
//         </div>
//     </div>
// </div>
    return (
        <>
            <div className="assembly-comlete">

                <SubHeader
                    handleDefectClick={handleDefectClick}
                    handleMachineTimeLineClick={handleMachineTimeLineClick}
                    machineName={machineName}
                    showDefects={true}
                    showTimeline={true}
                    showBackButton={false}
                />
                <div className="assembly-comlete-body">
                    <div className="complete-icon-container">
                        <img src={assembly_complete_svg} alt="danger svg"/>
                        <div>
                            {getTextHtml()}
                        </div>
                    </div>
                    <div className="assembly-comlete-save-button-container">
                        <button className="save-button" 
                            onClick={handleClick} >Home</button>
                    </div>  
                </div>
            </div>
        </>
  );
}

const mapStateToProps = state => {

    return {
      machineData: state.machineDataReducer.machineData,
      activeData: state.machineActiveDataReducer.activeData
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
        
//     }
// };

export default connect(mapStateToProps, null)(SubStageComplete);