import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { PAGE_CONST, TIMELINE_CONST } from '../../common/constant/CommonConstants';
import "./DefectDetails.css";

export default function DefectDetails(props) {

    const handleResolveClick = (id) => {
        if (props.handleResolveClick) props.handleResolveClick(id);
    }

    return (
        <div className="defect-details">
            {/* <div className="defect-header">
                <div className="defect-header-item defect-detail-name">
                    {props.data && props.data.id && `Defect ${props.data.id}` || 'Defect 1'}
                </div>
                {<div className="defect-header-item">
                    {moment(props.data.created_at).format(TIMELINE_CONST.DATE_TIME_FORMAT)}
                </div>}
            </div> */}
            <div className="defect-detail-description">
                <div className="defect-description-name">
                    Defect Description
                </div>
                <div className="defect-description-details">
                    {props.data && props.data.defect_description || 'Defect Description'}
                </div>
            </div>
            {props.data && props.data.component_name && <div className="defect-detail-description">
                <div className="defect-description-name">
                    Associated Part(s)
                </div>
                <div className="defect-description-details">
                    {props.data && props.data.component_name}
                </div>
            </div>}
            <div className="defect-detail-description">
                <div className="defect-description-name">
                    Defect Type
                </div>
                <div className="defect-description-details">
                    {props.data && props.data.defect_type || 'Defect Type'}
                </div>
            </div>
            {props.data && props.data.machine_stage_name && <div className="defect-detail-description">
                <div className="defect-description-name">
                    Stage
                </div>
                <div className="defect-description-details">
                    {props.data.machine_stage_name}
                </div>
            </div>}
            {props.data && props.data.created_by && <div className="defect-detail-description">
                <div className="defect-description-name">
                    Created By
                </div>
                <div className="defect-description-details">
                    {props.data.created_by}
                </div>
            </div>}
            {props.data && props.data.created_at && <div className="defect-detail-description">
                <div className="defect-description-name">
                    Created At
                </div>
                <div className="defect-description-details">
                    {moment(props.data.created_at).format(TIMELINE_CONST.DATE_TIME_FORMAT)}
                </div>
            </div>}
            {props.isResolved && 
                <>
                    {props.data.resolution_type && <div className="defect-detail-description">
                        <div className="defect-description-name">
                            Resolution Type
                        </div>
                        <div className="defect-description-details">
                            {props.data && props.data.resolution_type}
                        </div>
                    </div>}
                    {props.data.part_replacements && props.data.part_replacements.map((component) => {
                        return (
                            <>
                            <div className="defect-detail-description">
                                <div className="defect-description-name">
                                    New Component Id
                                </div>
                                <div className="defect-description-details">
                                    {component.new_component_name}
                                </div>
                            </div>
                            <div className="defect-detail-description">
                                <div className="defect-description-name">
                                    Old Component Id
                                </div>
                                <div className="defect-description-details">
                                    {component.old_component_name}
                                </div>
                            </div>
                        </>
                        )
                    })}
                    {props.data.resolution_description && <div className="defect-detail-description">
                        <div className="defect-description-name">
                            Resolution Description
                        </div>
                        <div className="defect-description-details">
                            {props.data && props.data.resolution_description}
                        </div>
                    </div>}
                    {props.data.resolved_by && <div className="defect-detail-description">
                        <div className="defect-description-name">
                            Resolved By
                        </div>
                        <div className="defect-description-details">
                            {props.data && props.data.resolved_by}
                        </div>
                    </div>}
                    {props.data.resolved_at && <div className="defect-detail-description">
                        <div className="defect-description-name">
                            Resolved At
                        </div>
                        <div className="defect-description-details">
                            {props.data && moment(props.data.resolved_at).format(TIMELINE_CONST.DATE_TIME_FORMAT)}
                        </div>
                    </div>}
                </>}
            {!props.isResolved && props.resolveButton && <div className="resolve-cta" onClick={() => handleResolveClick(props.data.id)}>
                Resolve
            </div>}
        </div>
    )
}
