import { orderDetailsAPI, orderMachineDetailsAPI, machinePartDetailAPI, excelFileUploadAPI, searchHeaderAPI } from '../adapter/machineCreationAdapter';

export const getOrderDetails = () => {

    var promise = new Promise(function (resolve, reject) {
        orderDetailsAPI().then((res) => {
            if (res && res.data && res.data.status === 200) {
                let response = res.data.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in order details Api :: " + err);
        });
    })

    return promise;
}
export const getOrderMachineDetails = (orderId) => {
    let reqData = {
        order_id: orderId
    }
    var promise = new Promise(function (resolve, reject) {
        orderMachineDetailsAPI(reqData).then((res) => {
            if (res && res.data && res.data.status === 200) {
                let response = res.data.data;

                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in orderMachine details Api :: " + err);
        });
    })

    return promise;
}

export const getMachinePartDetail = (orderId, machineId) => {
    let reqData = {
        order_id: orderId,
        machine_id: machineId
    }

    var promise = new Promise(function (resolve, reject) {
        machinePartDetailAPI(reqData).then((res) => {
            if (res && res.data && res.data.status === 200) {
                let response = res.data.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in orderMachine details Api :: " + err);
        });
    })

    return promise;
}
export const excelFileUpload = (uploadType, excelFile) => {
    let reqData = new FormData();
    reqData.append("upload_type", uploadType);
    reqData.append("excel_file", excelFile);

    var promise = new Promise(function (resolve, reject) {
        excelFileUploadAPI(reqData).then((res) => {
            if (res && res.data && res.data.status === 200) {
                let response = res.data.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured error occured in uploading file");
            }
        }).catch((err) => {
            console.log("error occured in uploading Api :: " + err);
        });
    })

    return promise;
}
export const searchHeader = (searchType, queryId) => {
    let reqData = {
        type: searchType,
        q: queryId
    };

    var promise = new Promise(function (resolve, reject) {
        searchHeaderAPI(reqData).then((res) => {
            if (res && res.data && res.data.status === 200) {
                let response = res.data.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured searching query");
            }
        }).catch((err) => {
            console.log("error occured in searching Api :: " + err);
        });
    })
    return promise;
}