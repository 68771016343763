import { Types } from "../actions/actionTypes";

let initialState = {
    userDetail: {}
}

export const userDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.USER_DETAILS: 
          return { ...state, userDetail: action.payload };
        default:
          return state;
    }
}