
import { Types } from "../actions/actionTypes";

let initialState = {
    activeData: {}
}

export const machineActiveDataReducer = (state = initialState, action) => {
  switch (action.type) {
      case Types.ACTIVE_SUBSTAGE:
        return { ...state, 
            activeData: {
                ...state.activeData,
                subStage: action.payload.subStage,
                stage: action.payload.state,
        }};
      default:
        return state;
  }
}