import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate} from "react-router-dom";
import green_dot_small from '../../common/icons/green-dot-small.svg';
import green_success_dot from '../../common/icons/green_success_dot.svg';
import grey_dot_small from '../../common/icons/grey_dot_small.svg';
import grey_success_dot from '../../common/icons/grey_success_dot.svg';
import yellow_dot_small from '../../common/icons/yellow_dot_small.svg';
import yellow_dot from '../../common/icons/yellow_dot.svg';
import "./AssemblyTimeLine.css";
import { PAGE_CONST, TIMELINE_CONST } from '../../common/constant/CommonConstants';
import SubHeader from '../SubHeader/SubHeader';
import { connect } from 'react-redux';
import { redirect } from '../../common/utils/RedirectionUtils';
import CreationFactory from '../../helper/CreationFactory';

function AssemblyTimeLine(props) {

    const STAGE = "STAGE";
    const SUB_STAGE = "SUB_STAGE";
    const IN_PROGRESS = "IN_PROGRESS";
    const COMPLETED = "COMPLETED";
    const NOT_STARTED = "NOT_STARTED";

    const navigate = useNavigate();
    const [timelineData, setTimelineData] = useState(null);
    
    let _creationFactory = new CreationFactory();

    useEffect(() => {

        _creationFactory.getTimeLine({machineId: props.machineData.id})
        .then((data) => {
            setTimelineData(data);
        })
        .catch((error) => {

        })

    }, []);

    const handleBackClick = () => {
        redirect(navigate, PAGE_CONST.MACHINE_PROGRESS_PAGE);
    }

    const getIconSource = (status, type) => {
        
        let src = '';
        switch (status) {
            case IN_PROGRESS:
                src =  (type === STAGE ? yellow_dot : yellow_dot_small);
                break;
            case COMPLETED:
                src =  (type === STAGE ? green_success_dot : green_dot_small);
                break;
            case NOT_STARTED:
                src =  (type === STAGE ? grey_success_dot : grey_dot_small);
                break;
        }

        return src;
    }

    const getTimeLineHtml = () => {
        let html = '';

        html = timelineData && timelineData.stages && timelineData.stages.map((stage) => {

            return (<div key={stage.id} className={stage.status===NOT_STARTED ? "timeline-stage blur-3" : "timeline-stage"}>
                <div className="timeline-stage-data">
                    <div className="timeline-stage-name-container">
                        <img className="timeline-stage-icon" src={getIconSource(stage.status, STAGE)} alt="success tick"/>
                        <span className="timeline-stage-name">{stage.name}</span>
                    </div>
                    <div className="timeline-stage-time">{stage.started_at && stage.started_at.substring(0, 10)}</div>
                </div>
                <div className="timeline-substage">
                    {
                        stage.substages && stage.substages.map((substage) => {
                            
                            return (
                                <div key={substage.id} className="timeline-substage-data">
                                    <div className="timeline-substage-name-container">
                                        <img src={getIconSource(substage.status, SUB_STAGE)} alt="success tick"/>
                                        <span className="timeline-substage-name">{substage.name} <br /> {substage.completed_by ? ('completed by: ' + substage.completed_by) : ''}</span>
                                    </div>
                                    <div className="timeline-substage-time">{substage.completed_at && moment(substage.completed_at).format(TIMELINE_CONST.DATE_TIME_FORMAT)}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>)
        })
        return html;
    }
    return (
        <>
            {timelineData &&<div className="assembly-timeline">
                <SubHeader  
                    handleBackClick={handleBackClick}
                    machineName={'Machine Timeline'}
                    showDefects={false}
                    showTimeline={false}
                    showBackButton={true}
                />
                <div className="assembly-timeline-body">
                    <div className="assembly-body-heading">
                        <div className="heading-name">
                            {timelineData.name}
                        </div>
                        <div className="heading-time">
                            {timelineData.total_time}
                        </div>
                    </div>
                    {getTimeLineHtml()}
                </div>
            </div>}
        </>
  );
}

const mapStateToProps = state => {

    return {
      machineData: state.machineDataReducer.machineData
    };
};

export default connect(mapStateToProps, null)(AssemblyTimeLine);