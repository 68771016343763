export const LOGIN_CONST = {
  SEND_OTP: "Send OTP",
  VERIFY_OTP: "Verify OTP",
  MOBILE_NUMBER: "MOBILE_NUMBER",
  EMAIL: "EMAIL",
  OTP: "OTP",
  MOBILE_NUMBER_ERROR: "Please enter a valid mobile number",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  LOGIN_KEY: "LOGIN_KEY",
  USER_DETAIL: "user_detail",
};

export const PAGE_CONST = {
  LOGIN_PAGE: "LOGIN_PAGE",
  HOME_PAGE: "HOME_PAGE",
  MACHINE_REGISTRATION_PAGE: "MACHINE_REGISTRATION_PAGE",
  MACHINE_REGISTRATION_PAGE_SCAN: "MACHINE_REGISTRATION_PAGE_SCAN",
  MACHINE_PROGRESS_PAGE: "MACHINE_PROGRESS_PAGE",
  ASSEMBLY_SUBSTAGE_PAGE: "ASSEMBLY_SUBSTAGE_PAGE",
  SUB_STAGE_COMPLETE_PAGE: "SUB_STAGE_COMPLETE_PAGE",
  ASSEMBLY_CONFIRMATION_SCREEN_PAGE: "ASSEMBLY_CONFIRMATION_SCREEN_PAGE",
  ASSEMBLY_TIMELINE_PAGE: "ASSEMBLY_TIMELINE_PAGE",
  MACHINE_CONSENT_PAGE: "MACHINE_CONSENT_PAGE",
  DEFECT_PAGE: "DEFECT_PAGE",
  CREATE_DEFECT_PAGE: "CREATE_DEFECT_PAGE",
  RESOLVE_DEFECT_PAGE: "RESOLVE_DEFECT_PAGE",
  PRODUCTION_ORDER_LISTING: "PRODUCTION_ORDER_LISTING",
  PRODUCTION_ORDER_DETAIL: "PRODUCTION_ORDER_DETAIL",
  MACHINE_DETAILS: "MACHINE_DETAILS",
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
  PO_MACHINE_FILTER: "PO_MACHINE_FILTER",
  PO_STATUS_FILTER: "PO_STATUS_FILTER",
  PART_DETAILS: "PART_DETAILS",
  TIMELINE: "TIMELINE",
}

export const TIMELINE_CONST = {
  DATE_TIME_FORMAT: "DD-MM-YYYY HH:mm",
  DATE_FORMAT: "DD-MM-YYYY",
  TIME_FORMAT: "HH:mm"
}

export const MACHINE_CONST = {
  SELECTED_MACHINE_TYPE: "SELECTED_MACHINE_TYPE",
};
export const SUB_STAGE_CONST = {
  SUBMIT: "Submit",
  REPORT_DEFECT_TEXT: "Report Defect",
  TEST_SUBSTAGE_COMPLETE: "testSubStageComplete",
  ASSEMBLY_SUBSTAGE_COMPLETE: "assemblySubStageComplete",
  COMPONENT_ADDITION: "COMPONENT_ADDITION",
  SUPER_COMPONENT_ADDITION: "SUPER_COMPONENT_ADDITION",
  COMPONENT_ARR: ["COMPONENT_ADDITION", "SUPER_COMPONENT_ADDITION"],
  TEST: "TEST",
  YES_NO: "YES_NO",
  YES: "YES",
  OK_NOK: "OK_NOK",
  START_DATE_END_DATE: "START_DATE_END_DATE",
  INPUT_FIELD: "INPUT_FIELD",
  NOK: "NOK",
  OK: "OK",
  DESCRIPTION: "description",
  BOX: "BOX",
  ASSEMBLY: "ASSEMBLY",
  CHOICE: "CHOICE",
  NORMAL: "NORMAL",
};
