import {
    createSubAssemblyDefectAPI,
    getSubAssemblyAllDefectsAPI,
    getSubAssemblyDefectByIdAPI,
    getSubAssemblyDefectResolutionTypesAPI,
    getSubAssemblyDefectTypesAPI, 
    resolveSubAssemblyDefectAPI
} from "../../adapter/machineCreationAdapter";

export class SuperComponentDefectFactory {
    
    constructor() {
        // some code
    }

    getDefectType = (productId) => {

        let data = {
            sub_assembly_type_id: productId
        }
    
        let promise = new Promise(function(resolve, reject) {
            getSubAssemblyDefectTypesAPI(data).then((res) => {
                // console.log("defect helper then:: " + JSON.stringify(res));
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                        reject("error occured");
                }
            });
        })
    
        return promise;
    }
    
    getDefectResolutionTypes = (productId) => {
    
        let data = {
            sub_assembly_type_id: productId
        }
    
        let promise = new Promise(function(resolve, reject) {
            getSubAssemblyDefectResolutionTypesAPI(data).then((res) => {
                // console.log("getDefectResolutionTypesAPI helper then:: " + JSON.stringify(res));
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    if (res.data.error) reject(res.data.error.message);
                    else reject("error occured");
                }
            });
        })
    
        return promise;
    }
    
    createDefect = (data) => {
    
        let reqData = {
            super_component_id: data.machineId,
            stage_id: data.stageId,
            defect_description: data.defectDescription,
            sub_stage_detail_id: data.subStageDetailId,
            defect_type_id: data.defectTypeId
        }
    
        let promise = new Promise(function(resolve, reject) {
            createSubAssemblyDefectAPI(reqData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    if (res.data.error) reject(res.data.error.message);
                    else reject("error occured, Please try again");
                }
            });
        })
    
        return promise;
    }
    
    resolveDefect = (data) => {
    
        let reqData = {
            defect_id: data.defectId,
            resolution_type_id: data.defectTypeId,
            resolution_description: data.defectDescription,
            components: []
        }
    
        if (data.defectedPartArray && data.defectedPartArray.length > 0) {
            let components = data.defectedPartArray.map((defectedComponent) => {
                return {
                    machine_sub_stage_detail_id: defectedComponent.id,
                    component_name: defectedComponent.new_id
                }
            })
            reqData.components = components;
        }
    
        let promise = new Promise(function(resolve, reject) {
            resolveSubAssemblyDefectAPI(reqData).then((res) => {
                // console.log("create defect then:: " + JSON.stringify(res));
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    if (res.data.error) reject(res.data.error.message);
                    else reject("error occured, Please try again");
                }
            });
        })
    
        return promise;
    }
    
    getAllDefects = (data) => {
    
        let reqData = {
            super_component_id: data.machineId
        }
    
        let promise = new Promise(function(resolve, reject) {
            getSubAssemblyAllDefectsAPI(reqData).then((res) => {
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    reject("error occured");
                }
            });
        })
    
        return promise;
    }
    
    getDefectById = (data) => {
        let reqData = {
            defect_id: data.defectId
        }
    
        let promise = new Promise(function(resolve, reject) {
            getSubAssemblyDefectByIdAPI(reqData).then((res) => {
                // console.log("get defect by id then:: " + JSON.stringify(res));
    
                if (res && res.data && res.data.status === 200) {
                    resolve(res.data.data)
                } else {
                    reject("error occured");
                }
            });
        })

        return promise;
    }
}