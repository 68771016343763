import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './modules/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import {routes} from './common/constant/RouteConstants';

import "./common/css/common.css";

function App() {

    return (
        
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <div className="App" id="App">
                    {/* {!isMobile() &&
                    <div className="app-header">
                        <Header />
                    </div>}
                    {!isMobile() && <div className="app-sidebar">
                        <SideBar />
                    </div>} */}
                    <BrowserRouter>
                        <Routes>
                            {routes.map((route) => {
                                return (
                                    <Route key={route.key} path={route.path} authNeeded={route.authNeeded} element={route.element} />
                                )
                            })}
                            <Route path="*" element={<Navigate to="/login" />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </PersistGate>
        </Provider>
  );
}

export default App;
