import SideBar from "../../component/SideBar/SideBar";
import WebHeader from "../../component/WebHeader/WebHeader";
import orange_svg from "../../common/icons/orange_circle.svg";
import green_svg from "../../common/icons/green_circle.svg";
import blue_svg from "../../common/icons/blue_circle.svg";
import white_plus_svg from "../../common/icons/white_plus.svg";
import inprogress_circle from "../../common/icons/inprogress_circle.svg"

import new_orders_grey from "../../common/icons/new_orders_grey.svg"
import "./POListing.css";
import CustomSelect from "../../component/Select/Select";
import ReactTable from "../../component/ReactTable/ReactTable";
import DragDropFileUpload from "../../component/FileUpload/DragDropFileUpload";
import { useEffect, useState } from "react";
import { redirect } from "../../common/utils/RedirectionUtils";
import { useNavigate } from "react-router-dom";
import { PAGE_CONST, TIMELINE_CONST } from "../../common/constant/CommonConstants";
import { getOrderDetails } from '../../helper/ProductionOrderHelper';
import moment from "moment";


function POListing(props) {

    const navigate = useNavigate();
    const [isAddFileVisible, setIsAddFileVisible] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [dataContainer, setDataContainer] = useState({});
    const [filterType, setFilterType] = useState(null);
    const [poMachineFilter, setPoMachineFilter] = useState(null);
    const [poStatusFilter, setPoStatusFilter] = useState(null);

    useEffect(() => {
        handleOrderGet();
    }, [])

    const handleOrderGet = () => {
        getOrderDetails().
            then((res) => {
                setDataContainer(res);
            }).catch((err) => {
                console.log(err);
            })
    };

    const handleSelectChange = (type, item) => {
        if (type && item) {
            setFilterType(type);
            if (type === PAGE_CONST.PO_MACHINE_FILTER) {
                if (item)
                    setPoMachineFilter(item.name);
            }
            else {
                if (item)
                    setPoStatusFilter(item.name);
            }
        }
        else {
            setFilterType(null);
            if (!poMachineFilter)
                setPoMachineFilter(null);
            if (!poStatusFilter)
                setPoStatusFilter(null);
        }


        console.log("selected item for type :: " + JSON.stringify(type) + " , item:: " + JSON.stringify(item));
    }

    const updateFileVisible = () => {

        if (!isAddFileVisible) setIsAddFileVisible(true);
    }

    const closeFileUploadPopUp = () => {
        setIsAddFileVisible(false);
    }

    const uploadFileToServer = (file) => {

        setUploadError("error occured in uploading");
        console.log("file to upload:: " + file);
    }

    const handleFileTypeSelectChange = (item) => {
        console.log(item);
    }

    const handleDetailRedirection = (orderId) => {

        console.log("redirecting to orderId :: " + orderId);
        redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_DETAIL, { orderId: orderId });
    }

    const getTableColumn = () => {
        const columns = [
            {
                title: 'PRODUCTION ORDER ID',
                dataIndex: 'order_id',
                key: 'production_order_id',
                className: 'production_order_id_class',
                onCell: (record, index) => ({
                    onClick(e) {
                        if (record.order_id) {
                            console.log(record);
                            handleDetailRedirection(record.order_id);
                        }
                    }
                })
            },
            {
                title: 'CUSTOMER',
                dataIndex: 'customer',
                key: 'customer'
            },
            {
                title: 'PRODUCT NAME',
                dataIndex: 'product_name',
                key: 'product_name',
                // render: (row)=> {
                //     console.log("row", row)
                //     return (
                //         <span>{row}</span>
                //     )
                // },
                Cell: (row) => {
                    console.log("row", row);
                    return (
                        <div>
                            <span >{row}</span>
                            {/* <span >{row.product_type}</span> */}
                        </div>
                    )
                }
            },
            {
                title: 'NO. OF MACHINES',
                dataIndex: 'num_of_machines',
                key: 'number_machines',
            },
            {
                title: 'COMPLETED MACHINES',
                dataIndex: 'num_of_machines_completed',
                key: 'completed_machines',
            },
            {
                title: 'DATE OF ORDER',
                dataIndex: 'created_at',
                key: 'date_of_order',
                render: date => {
                    if (date)
                        return <div>{moment(date).format(TIMELINE_CONST.DATE_FORMAT)}</div>
                    else return "-";
                }
            },
            {
                title: 'COMPLETION DATE',
                dataIndex: 'completed_at',
                key: 'completion_date',
            },
            {
                title: 'STATUS',
                dataIndex: 'status',
                key: 'status',
                className: 'status_class',
                render: row => {
                    return <div className={`${row.replaceAll(/\s/g, "")} status`}>{row.replaceAll('_', " ")}</div>
                }
            }
        ];

        return columns;
    }
    const getTableData = () => {
        const data = dataContainer.orders;
        let filteredData = data;

        if (filterType === PAGE_CONST.PO_MACHINE_FILTER) {
            filteredData = data.filter(e => e.product_name === poMachineFilter);
        }
        if (filterType === PAGE_CONST.PO_STATUS_FILTER) {
            filteredData = data.filter(e => e.status === poStatusFilter);
        }
        return filteredData;
    }

    return (
        <div className="po-listing">
            <WebHeader />
            <SideBar />
            <div className="po-listing-body">
                <div className="po-listing-high-details">
                    <div className="po-listing-high-level-details-container">
                        <div className="po-listing-machine-text">
                            Production Orders
                        </div>
                        <div className="po-listing-machine-details">
                            <div className="status-container">
                                <div className="status-details">
                                    <img className="blue-circle-svg" src={new_orders_grey} alt="Link Icon" />
                                    <span className="status-text">New Orders</span>
                                </div>
                                <div className="status-number">
                                    {dataContainer.new_orders}
                                </div>
                            </div>
                            <div className="status-container">
                                <div className="">
                                    <img className="inprogress-circle-svg" src={inprogress_circle} alt="Link Icon" />
                                    <span className="status-text">Inprogress</span>
                                </div>
                                <div className="status-number">
                                    {dataContainer.in_progress_orders}
                                </div>
                            </div>
                            <div className="status-container">
                                <div className="">
                                    <img className="green-circle-svg" src={green_svg} alt="Link Icon" />
                                    <span className="status-text">Completed</span>
                                </div>
                                <div className="status-number">
                                    {dataContainer.completed_orders}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="po-listing-details-body">
                    <div className="order-list-filter-upload-container">
                        <div className="order-list-filter-container">
                            <div className="machine-type-filter">
                                <CustomSelect
                                    id="header-search-select"
                                    handleChange={(item) =>
                                        handleSelectChange(PAGE_CONST.PO_MACHINE_FILTER, item)}
                                    label='label'
                                    options={[
                                        { name: 'V310+', label: 'V310+' },
                                    ]}
                                    placeholder='Machine Type'
                                    style={{
                                        'controlHeight': '37px',
                                        'fontSize': '14px',
                                        'optionPadding': '10%',
                                        'controlMinWidth': '135px'
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                            <div className="machine-status-filter">
                                <CustomSelect
                                    id="header-search-select"
                                    handleChange={(item) =>
                                        handleSelectChange(PAGE_CONST.PO_STATUS_FILTER, item)}
                                    label='label'
                                    options={[
                                        { name: 'IN_PROGRESS', label: 'In-Progress' },
                                        { name: 'COMPLETED', label: 'Completed' },
                                        { name: 'DEFECT', label: 'Defect' },
                                        { name: 'NOT_STARTED', label: 'Not Started' }
                                    ]}
                                    placeholder='Status'
                                    style={{
                                        'controlHeight': '37px',
                                        'controlMinWidth': '135px',
                                        'fontSize': '14px',
                                        'optionPadding': '10%'
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        </div>
                        <div className="upload-button-container" onClick={updateFileVisible}>
                            <img className="white-plus-svg" src={white_plus_svg} alt="white plus svg" />

                            <span className="upload-text">Add</span>
                        </div>
                    </div>
                    <div className="react-table-container">
                        <ReactTable columns={getTableColumn()} data={getTableData()} rowKey="order_id" />
                    </div>
                </div>
            </div>
            {isAddFileVisible && <div className="file-upload-popup-container">
                <DragDropFileUpload
                    close={closeFileUploadPopUp}
                    upload={uploadFileToServer}
                    error={uploadError}
                    fileTypeOptions={[
                        { label: "Component Upload", name: "Component Upload" },
                        { label: "Production Order Upload", name: "Production Order Upload" },
                    ]}
                    handleFileTypeSelectChange={handleFileTypeSelectChange}
                />
            </div>}
        </div>
    )
}

export default POListing;