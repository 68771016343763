import CustomSelect from "../Select/Select";
import SearchField from "react-search-field";
import "./WebHeader.css";
import down_arrow_svg from "../../common/icons/down_arrow.svg";
import { useState, useEffect } from "react";
import { logout } from '../../helper/LoginHelper';
import { blurApp, unBlurApp } from '../../common/utils/CommonUtils';
import { redirect } from '../../common/utils/RedirectionUtils';
import { useNavigate } from "react-router-dom";
import { PAGE_CONST } from '../../common/constant/CommonConstants';
import { connect } from 'react-redux';
import { searchHeader } from '../../helper/ProductionOrderHelper';

function WebHeader(props) {

    const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState(null);
    const [searchType, setSearchType] = useState(null);
    const [queryId, setQueryId] = useState(null);
    const [errMessage, setErrMessage] = useState("");

    const handleSearchClick = (result) => {
        setQueryId(result)
        console.log("result of the search field :: " + JSON.stringify(result));

        if (queryId) {
            searchHeader(searchType, queryId)
                .then((res) => {
                    if (searchType === "MACHINES") {
                        redirect(navigate, PAGE_CONST.PART_DETAILS,
                            { orderId: res.result[0].order_id, machineId: res.result[0].machine_id })
                    } else if (searchType === "ORDERS") {
                        if (res.result[0].order_id)
                            redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_DETAIL, { orderId: res.result[0].order_id })
                    }
                }).catch((err) => {
                    console.log(err);
                    setErrMessage("Does not exist");
                })
        }
        // handle search click
    }
    useEffect(() => {
        setErrMessage("");
    }, [queryId, searchType])

    useEffect(() => {
        // save user details to state

        let userDetails = props.userDetails;
        if (userDetails) setUserDetails(userDetails);
    }, [])

    const handleSelectChange = (item) => {
        if (item && item.name)
            setSearchType(item.name);
        else setSearchType(null);
        // console.log("selected item in header :: " + JSON.stringify(item));
    }

    const toggleMoreDetails = () => {
        let tempMoreDetailsOpen = isMoreDetailsOpen;
        setIsMoreDetailsOpen(!tempMoreDetailsOpen);
    }

    const handleSignOutClick = () => {
        logout()
            .then((res) => {
                unBlurApp();
                redirect(navigate, PAGE_CONST.LOGIN_PAGE);
            }).catch((err) => {
                console.log("err:: " + err);
            });

        console.log("sign out clicked !!");
    }

    return (
        <div className="web-header">
            <div className="header-content">
                <div className="header-text">
                    Production Orders
                </div>
                <div className="web-header-data">
                    <div className="web-header-icons-container">
                        {errMessage && <div className="error-message">{errMessage}</div>}

                        <div className="web-header-icons dropdown-container">
                            <CustomSelect
                                id="header-search-select"
                                handleChange={(item) => handleSelectChange(item)}
                                label='label'
                                options={[
                                    { name: 'ORDERS', label: 'ORDERS' },
                                    { name: 'MACHINES', label: 'MACHINES' },
                                ]}

                                placeholder='Search Type'
                                style={{
                                    'controlHeight': '37px',
                                    'fontSize': '14px',
                                    'optionPadding': '10px'
                                }}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        <div className="web-header-icons search-container">
                            <SearchField
                                placeholder="Search..."
                                onChange
                                onSearchClick={(result) => handleSearchClick(result)}
                                classNames="test-class"
                            />
                        </div>
                        <div className="web-header-icons web-user-detail-container" onClick={() => toggleMoreDetails()}>
                            <div className="web-header-user-name">
                                {(userDetails && userDetails.name) || 'User Name'}
                            </div>
                            <div className="down-arrow-container">
                                <img className="down-arrow-svg" src={down_arrow_svg} alt="Link Icon" />
                            </div>
                        </div>
                    </div>
                    {isMoreDetailsOpen && <div className="more-user-details-container">
                        <div className="more-user-name more-user-detail">
                            {(userDetails && userDetails.name) || 'User Name'}
                        </div>
                        <div className="more-user-mail more-user-detail">
                            {(userDetails && userDetails.email) || 'User Email'}
                        </div>
                        <div className="more-user-role more-user-detail">
                            Role:&emsp;&emsp;{(userDetails && userDetails.role) || 'Admin'}
                        </div>
                        <div className="more-user-sign-out more-user-detail" onClick={() => handleSignOutClick()}>
                            SIGN OUT
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {

    return {
        userDetails: state.userDetailsReducer.userDetail
    };
};

export default connect(mapStateToProps)(WebHeader);