
// import initialState from "../store/initialState";
import { Types } from "../actions/actionTypes";

let initialState = {
    machineData: {}
}

export const machineDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SAVE_MACHINE_DATA: 
          return { ...state, machineData: action.payload };
        default:
          return state;
    }
}